import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { RES_CONTROLLER } from 'constants/URLS'
import moment from 'moment'
import { AppDispatch, RootState } from 'store/configureStore'
import { isAxiosError } from 'utils/errors'
import {
  IMyReservationParams,
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate,
  IReservationsError
} from '../../models/Reservation.model'
import { getUrlParams } from '../../utils/urlrelated'
import { hide, show } from '../reducers/LoaderReducer'
import { countSeats } from './countSeatsOnDates'
import axiosInstance, { statuses } from 'app/axios'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'
import { showToast } from 'store/reducers/ToastReducer'

export const myReservations = createAsyncThunk<
  IMyReservationsResponse<IReservationContentWithDate>,
  IMyReservationParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    state: RootState
    dispatch: AppDispatch
  }
>(
  'reservation/myReservations',
  async (
    params: IMyReservationParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      let path = getUrlParams(params)
      dispatch(show())

      const { data } = await axiosInstance.get<
        IMyReservationsResponse<IReservationContent>
      >(RES_CONTROLLER.myReservations + path)

      if (data.content?.length > 0) {
        dispatch(
          countSeats({
            fromDate: data.first
              ? new Date(data.content[0].dateStart).toISOString()
              : new Date(
                  getState().reservations?.myreservations?.content[0].dateStart
                ).toISOString(),
            toDate: moment(data.content[data.content.length - 1].dateStart)
              .add(1, 'days')
              .toISOString()
          })
        )
      } else {
        return
      }

      dispatch(hide())

      if (params.page > 0) {
        if (data.first) {
          return {
            ...data
          }
        }
        return {
          ...data,
          content: [
            ...getState().reservations.myreservations.content,
            ...data.content
          ]
        }
      }

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: countSeats,
              params
            })
          )
        }
        const error = err as AxiosError<IReservationsError>

        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
