import { Fab, Theme } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { makeStyles } from '@material-ui/styles'
import { ADD_RESERVATION_PATH } from 'app/routes/constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  newReservation: {
    borderRadius: '20px 2px 20px 20px',
    height: '45px',
    boxShadow: `3px 3px ${theme.palette.primary.dark}`,
    marginRight: '10px',
    '&:hover': {
      boxShadow: `3px 3px ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.secondary.main,
      filter: 'brightness(0.85)'
    }
  }
}))
const NewReservation = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const handleClick = () => navigate(ADD_RESERVATION_PATH)
  return (
    <Fab
      onClick={handleClick}
      color="secondary"
      aria-label="nuova prenotazione"
      className={classes.newReservation}
    >
      <AddRoundedIcon />
    </Fab>
  )
}

export default NewReservation
