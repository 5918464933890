import { Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { keyframes } from 'styled-components'
import styled from 'styled-components/macro'

const comparsa = keyframes`
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }

`

export const Hr = styled.div`
  margin: 30px 0;
  height: 3px;
  border: 1px solid ${props => props.theme.palette.primary.light};
  border-radius: 3px;
  background-color: ${props => props.theme.palette.primary.main};
`

export const StyledSelect = styled(Select)`
  background-color: ${props => props.theme.palette.primary.main};
  border-radius: 15px;
  padding: 15px;
  height: 36px;
  box-shadow: 0 3px ${props => props.theme.palette.primary.dark};

  :before,
  :after {
    display: none;
  }

  :hover {
    background-color: ${props => props.theme.palette.primary.dark};
    box-shadow: none;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }
`

export const StyledOption = styled.option`
  cursor: pointer;
  font-size: 18px;
  padding: 0 10px;

  :hover {
    color: ${props => props.theme.palette.primary.main};
  }
`

export const StyledTextField = styled(TextField)`
  > div {
    border-radius: 25px;
  }

  fieldset {
    border: 2px solid ${props => props.theme.palette.primary.light};
  }
`

export const MainCard = styled.div`
  margin: auto;
  font-family: 'Do Hyeon';
  flex-direction: column;
  background: whitesmoke;
  border-radius: 8px;
  border: #f6f6f6 6px solid;
  padding: 31px;
  animation-name: ${comparsa};
  animation-duration: 1s;
  display: flex;

  &:hover {
    cursor: default;

    &:after {
      transform: translateX(100%) skewX(-30deg);
    }
  }
`
