import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'

export interface IAction {
  callback: () => void
  label: string
  secondaryButton?: boolean
}

export interface IModal {
  title: string
  content?: string
  actions: IAction[]
  open: boolean
  closeCallback?: () => void
}

const initialState: IModal = {
  title: '',
  content: '',
  actions: [],
  open: false
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<IModal>) {
      state.title = action.payload.title
      state.content = action.payload.content
      state.actions = action.payload.actions
      state.open = action.payload.open
      state.closeCallback = action.payload.closeCallback
    },
    closeModal(state) {
      state.title = ''
      state.content = ''
      state.actions = []
      state.open = false
    }
  }
})

export const selectModalState = (state: RootState): IModal => state.modal

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
