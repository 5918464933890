import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
const MinimalLayout = () => {
  useEffect(() => {
    const r = {
      background: `#E1E8E8`
    }
    const root = document.getElementById('root')
    Object.assign(root?.style, r)
  }, [])
  return (
    <>
      <Outlet />
    </>
  )
}

export default MinimalLayout
