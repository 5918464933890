import React from 'react'
import { showToast } from '../../../store/reducers/ToastReducer'
import { useDispatch } from 'react-redux'

const NetworkStatus = () => {
  const dispatch = useDispatch()

  const NetStatusNotification = (isOnline: boolean, message: string) => {
    if (isOnline) {
      dispatch(
        showToast({
          type: 'success',
          message: message
        })
      )
    } else {
      dispatch(
        showToast({
          type: 'error',
          message: message,
          time: null
        })
      )
    }
  }

  window.addEventListener('load', () => {
    window.addEventListener('online', () => {
      NetStatusNotification(true, 'Sei tornato online!!')
    })

    window.addEventListener('offline', () => {
      NetStatusNotification(false, 'Connessione di rete persa!!')
    })
  })

  return <div></div>
}
export default NetworkStatus
