import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showToast } from '../../store/reducers/ToastReducer'
import { isAxiosError } from '../../utils/errors'
import axiosInstance, { statuses } from '../../app/axios'
import { GROUP_CONTROLLER } from '../../constants/URLS'
import { CreateGroup, Group } from '../../models/Auth.model'
import { IReservationsError } from 'models/Reservation.model'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'

export const getAllGroups = createAsyncThunk<
  Group[],
  undefined,
  {
    rejectValue: AxiosResponse
  }
>('groupControl/findAllEnabled', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get<Group[]>(
      GROUP_CONTROLLER.getGroups
    )
    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError<IReservationsError>
      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})

export const createGroup = createAsyncThunk<
  any,
  CreateGroup,
  {
    rejectValue: AxiosResponse
  }
>(
  'groupControl/addGroup',
  async (requestBody: CreateGroup, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axiosInstance.post(
        `${GROUP_CONTROLLER.createGroup}`,
        {
          ...requestBody
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Gruppo creato con successo'
        })
      )
      dispatch(getAllGroups())
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: createGroup,
              params: requestBody
            })
          )
        }
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              'Errore durante la creazione del gruppo'
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)

export interface IDeleteParams {
  id: number
}

export const deleteGroup = createAsyncThunk<
  any,
  IDeleteParams | undefined,
  {
    rejectValue: AxiosResponse
  }
>(
  'groupControl/delete',
  async ({ id }: IDeleteParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.delete(
        `${GROUP_CONTROLLER.deleteGroup}/${id}`
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Gruppo eliminato con successo'
        })
      )
      dispatch(getAllGroups())

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message: "Errore durante l'eliminazione del gruppo"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)

export const updateGroup = createAsyncThunk<
  any,
  Group,
  {
    rejectValue: AxiosResponse
  }
>(
  'groupControl/update',
  async (requestBody: Group, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.put(
        `${GROUP_CONTROLLER.updateGroup}`,
        {
          ...requestBody
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Gruppo aggiornato con successo'
        })
      )
      dispatch(getAllGroups())

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message: "Errore durante l'aggiornamento del gruppo"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
