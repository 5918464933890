/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations'
import { _t } from 'utils/messages'

export const messages = {
  copyright: () => _t(translations.Copyright.title, 'Copyright © '),
  company: () => _t(translations.Copyright.company, 'TAK'),
  description: () => _t(translations.HomePage.description, 'Prenota postazione')
}
