import styled from 'styled-components'
import { IShadowButton } from '.'

export const MainButton = styled.div<IShadowButton>`
  opacity: ${props => (props.disabled ? '0.5' : 1)};
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border-radius: 3em;
  display: inline-block;
  line-height: 1;
  border: 0;
  /* font-family: 'Staatliches'; */
  /* font-weight: 700; */
  /* color: ${p => p.color}; */
  /* border: none; */
  /* outline: none; */
  text-align: center;
  overflow: hidden;
  position: relative;
  /* border-radius: 2px; */
  letter-spacing: 2px;
  box-shadow: ${'0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
  text-transform: uppercase;
  background-color: ${props => props.backgroundColor};
  transition: all 0.2s ease-in;
  margin: 15px 0;

  &:after {
    top: -50%;
    z-index: 1;
    content: '';
    width: 150%;
    height: 200%;
    position: absolute;
    left: calc(-150% - 40px);
    background-color: rgba(255, 255, 255, 0.2);
    transform: skewX(-40deg);
    transition: all 0.2s ease-out;
  }

  &:hover {
    cursor: default;
    box-shadow: ${'0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'};

    &:after {
      transform: translateX(100%) skewX(-30deg);
    }
  }

  &.active {
    box-shadow: ${'0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'};
  }
`

interface BntProps {
  size: 'small' | 'medium' | 'large'
  primary: boolean
  color: string
}

export const BtnText = styled.div<BntProps>`
  z-index: 2;
  display: block;
  position: relative;
  background-color: transparent;
  color: ${({ primary, color }) => (primary ? 'white' : color)};
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '12px'
      case 'medium':
        return '14px'
      case 'large':
        return '16px'
    }
  }};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '10px 16px'
      case 'medium':
        return '11px 20px'
      case 'large':
        return '12px 24px'
    }
  }};
`

/*
.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.storybook-button--primary {
  color: white;
  background-color: #1ea7fd;
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
*/
