import { Grid } from '@material-ui/core'
import { IBigDataContent } from 'models/Reservation.model'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { toApproveReservations } from 'store/thunk/toApproveReservations'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import {
  filterReservations,
  groupReservationsByDay,
  normalizeReservation
} from '../../services/reservationService'
import Loader from '../Loader'
import NoData from '../NoData'
import ItemsGroup from './ItemsGroup'
import { useStyles } from './MyBooks'

function ToApprove({ filter }) {
  const { pageSize } = ENVIRONMENT_VARIABLES
  const { toapprovereservations, loading } = useSelector(selectReservations)

  const { pageable = { pageNumber: 0 }, last = false, empty = true } = {
    ...toapprovereservations
  }
  const { pageNumber = 0 } = { ...pageable }

  const [bigData, setBigData] = useState<IBigDataContent[]>([])

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    // get initial reservations only if content is empty
    dispatch(
      toApproveReservations({
        fromDate: moment().startOf('day').toISOString(),
        page: 0,
        size: pageSize,
        sort: 'dateStart'
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const normalizedReservations = normalizeReservation(toapprovereservations)
    const filteredReservations = filterReservations(
      'CANCELLED',
      normalizedReservations
    )
    const groupedByDay = groupReservationsByDay(filteredReservations)

    setBigData(groupedByDay)
  }, [toapprovereservations])

  const handleLoadMore = (inView: boolean) => {
    if (inView && !last && !loading) {
      dispatch(
        toApproveReservations({
          fromDate: moment().startOf('day').toISOString(),
          page: pageNumber + 1,
          size: pageSize,
          sort: 'dateStart'
        })
      )
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {bigData?.map((el, index) => (
          <ItemsGroup
            filter={filter}
            key={index}
            group={el}
            lastMonth={index + 1 === bigData.length}
            showReservationName
          />
        ))}
        {!bigData.length && !loading && (
          <NoData
            title="Tutti in SmartWorking!"
            description="nessuna prenotazione trovata"
          />
        )}
        {bigData.length > 0 && !empty && !last && (
          //TODO Ingore error detected by pipeline

          <InView as="div" onChange={inView => handleLoadMore(inView)}>
            <Loader visible={true} />
          </InView>
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(ToApprove)
