import { value } from 'constants/conf'

type environmentProps = {
  maximumAvailableSeats: number
  pageSize: number
}

export const ENVIRONMENT_VARIABLES: environmentProps = {
  maximumAvailableSeats:
    Number(value('REACT_APP_MAXIMUM_AVAILABLE_SEATS')) || 4,
  pageSize: Number(value('REACT_APP_PAGE_SIZE')) || 10
}
