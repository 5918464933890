import { Chip, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  monthChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    transition: 'all .2s ease-in-out',
    backgroundColor: theme.palette.secondary.main,
    border: '0px',
    padding: '20px 10px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 10px',
      height: '35px'
    }
  },
  monthChipLabel: {
    fontSize: '26px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px'
    }
  }
}))

const ChipMonth: React.VFC<{ month: string }> = ({ month }) => {
  const classes = useStyles()
  return (
    <Chip
      className={classes.monthChip}
      color="primary"
      label={month}
      classes={{ label: classes.monthChipLabel }}
    />
  )
}

export default ChipMonth
