import styled from '@emotion/styled'

export const StyledSegmentedControlContainer = styled.div`
  font-family: 'Do Hyeon';

  @media screen and (max-width: 900px) {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`
