import { createSlice } from '@reduxjs/toolkit'

interface IErrorActionState {
  error: boolean
  lastAction: (p: any) => any
  params: any
}

export const initialState: IErrorActionState = {
  error: false,
  lastAction: () => {},
  params: {}
}

export const ErrorActionSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    setError(state, action){
      state.error = action.payload
    },
    changeLastAction(state, action){
      state.lastAction = action.payload.f
      state.params = action.payload.params
    }
  }
})

export const { setError, changeLastAction } = ErrorActionSlice.actions

export default ErrorActionSlice.reducer
