export interface infoSeatReserv {
  available: number
  total: number
  booked: number
}
const seatReser: infoSeatReserv = {
  available: 0,
  total: 0,
  booked: 0
}

export const getSeatDay = (numberOfSeatsA: number, numberOfSeats: number) => {
  seatReser.total = numberOfSeats
  seatReser.available = numberOfSeatsA
  seatReser.booked = numberOfSeats - numberOfSeatsA
  return seatReser
}
