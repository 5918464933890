/**
 *
 * NotFoundPage
 *
 */
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { messages } from './messages'
import { P } from './P'

interface Props {}

export function NotFoundPage(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation()

  return (
    //TODO ignore error detected by pipeline
    // @ts-ignore
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{t(...messages.title())}</title>
        <meta name="description" content={t(...messages.description())} />
      </Helmet>
      <Wrapper>
        <Title>
          4
          <span role="img" aria-label="Crying Face">
            😢
          </span>
          4
        </Title>
        <P>{t(...messages.content())}</P>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: black;
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`
