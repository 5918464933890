import React from 'react'
import { BtnText, MainButton } from './ShadowButton.styled'

export interface IShadowButton {
  primary?: boolean
  id?: string
  backgroundColor?: string
  size?: 'small' | 'medium' | 'large'
  buttonText?: string
  color: 'white' | string
  onClick: any
  disabled: boolean
}

export const ShadowButton: React.FC<IShadowButton> = ({
  primary = true,
  size = 'medium',
  onClick,
  disabled,
  ...props
}) => (
  <MainButton {...props} onClick={onClick} id={props.id} disabled={disabled}>
    <BtnText {...props} color={props.color} primary={primary} size={size}>
      {props.buttonText}
    </BtnText>
  </MainButton>
)

export default ShadowButton
