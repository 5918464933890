import {
  IBigDataContent,
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate
} from '../../models/Reservation.model'
import { getMonthNameByMonthNumber } from '../../utils/dateformat'

type FilterType = 'CANCELLED' | 'ACCEPTED' | 'REJECTED' | 'PENDING'

export const normalizeReservation = (
  reservation: IMyReservationsResponse<IReservationContentWithDate>
) => {
  return reservation?.content.reduce((acc, curr) => {
    const found = acc.find(
      a =>
        a.month ===
        getMonthNameByMonthNumber(
          new Date(curr.dateStart).getMonth(),
          'uppercase'
        )
    )

    const foundIndex = acc.findIndex(
      a =>
        a.month ===
        getMonthNameByMonthNumber(
          new Date(curr.dateStart).getMonth(),
          'uppercase'
        )
    )

    if (foundIndex > -1) {
      found.reservations.push(curr)
      acc.splice(foundIndex, 1, found)
      return acc
    } else {
      return [
        ...acc,
        {
          month: getMonthNameByMonthNumber(
            new Date(curr.dateStart).getMonth(),
            'uppercase'
          ),
          reservations: [curr]
        }
      ]
    }
  }, [])
}

export const filterReservations = (
  filterType: FilterType,
  normalizedreservations: any
) => {
  if (!normalizedreservations) return []
  return normalizedreservations
    .map(a => {
      const filteredReservations = a.reservations.filter(
        b => b.reservationStatus.status !== filterType
      )
      return { ...a, reservations: filteredReservations }
    })
    .filter(l => l.reservations.length)
}

//group by day
export const groupReservationsByDay = (reservations): IBigDataContent[] => {
  return reservations
    .map(groupedByMonth => {
      const groupedByDay = groupedByMonth.reservations.reduce((acc, curr) => {
        const found = acc.find(a => a.day === curr.dateStart)
        const foundIndex = acc.findIndex(a => a.day === curr.dateStart)
        if (foundIndex > -1) {
          found.reservations.push(curr)
          acc.splice(foundIndex, 1, found)
          return acc
        } else {
          return [...acc, { day: curr.dateStart, reservations: [curr] }]
        }
      }, [])
      return { month: groupedByMonth.month, days: [...groupedByDay] }
    })
    .map(monthlyGroupedReservation => {
      const totalReservations = monthlyGroupedReservation.days.reduce(
        (acc, curr) => {
          return acc + curr.reservations.length
        },
        0
      )
      return { ...monthlyGroupedReservation, totalReservations }
    })
}

export const handleContent = (
  data: IMyReservationsResponse<IReservationContent>
): IMyReservationsResponse<IReservationContentWithDate> => {
  const tmpContent: IReservationContentWithDate[] = data.content.map(value => {
    const d = new Date(value.dateStart)

    return {
      ...value,
      day: d.getDate().toString(),
      month: (d.getMonth() + 1).toString(),
      year: d.getFullYear().toString()
    }
  })

  return {
    ...data,
    content: tmpContent.sort((a, b) => {
      return a.dateStart > b.dateStart ? 1 : -1
    })
  }
}
