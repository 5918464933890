import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { colorByStatus } from '../Booking/Booking.Styled'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '95%',
    height: 'auto',
    background: 'white',
    paddingLeft: '25px',
    paddingTop: '15px',
    borderRadius: '25px',
    paddingBottom: '15px',
    margin: 'auto',
    marginTop: '25px',
    marginBottom: '50px',
    [theme.breakpoints.up('md')]: {
      width: '25%'
    }
  },
  containerDesc: {
    display: 'flex',
    flexDirection: 'column'
  },
  singularContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  boxcolor: {
    width: '20px',
    height: '20px',
    borderRadius: '50%'
  }
}))

const LegendaCalendar = () => {
  const classes = useStyles()
  return (
    <section className={classes.container}>
      <Typography variant="h5" color="initial">
        Legenda
      </Typography>
      <div className={classes.containerDesc}>
        <div className={classes.singularContainer}>
          <div
            className={classes.boxcolor}
            style={{ background: ' #37a5d7' }}
          />
          <Typography variant="subtitle1" color="initial">
            Giorno attuale
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div className={classes.boxcolor} style={{ background: '#1655c8' }} />
          <Typography variant="subtitle1" color="initial">
            Giorno selezionato
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div className={classes.boxcolor} style={{ background: '#d9534f' }} />
          <Typography variant="subtitle1" color="initial">
            Ultimi posti rimasti
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div className={classes.boxcolor} style={{ background: 'green' }} />
          <Typography variant="subtitle1" color="initial">
            Posti disponibili
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div
            className={classes.boxcolor}
            style={{ background: `${colorByStatus.PENDING}` }}
          />
          <Typography variant="subtitle1" color="initial">
            Prenotazione in sospeso
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div
            className={classes.boxcolor}
            style={{ background: `${colorByStatus.ACCEPTED}` }}
          />
          <Typography variant="subtitle1" color="initial">
            Prenotazione in corso
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div
            className={classes.boxcolor}
            style={{ background: `${colorByStatus.CANCELLED}` }}
          />
          <Typography variant="subtitle1" color="initial">
            Prenotazione annullata
          </Typography>
        </div>

        <div className={classes.singularContainer}>
          <div
            className={classes.boxcolor}
            style={{ background: `${colorByStatus.REJECTED}` }}
          />
          <Typography variant="subtitle1" color="initial">
            Prenotazione rifiutata
          </Typography>
        </div>
      </div>
    </section>
  )
}

export default LegendaCalendar
