import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import { IBigDataContent } from '../../../models/Reservation.model'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import { myReservations } from '../../../store/thunk/myReservations'
import {
  filterReservations,
  groupReservationsByDay,
  normalizeReservation
} from '../../services/reservationService'
import Loader from '../Loader'
import NoData from '../NoData'
import ItemsGroup from './ItemsGroup'

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflow: 'hidden'
    },
    paper: {
      height: 140,
      width: 100
    },
    control: {
      padding: theme.spacing(2)
    }
  })
)

function MyBooks({ filter }) {

  const { pageSize } = ENVIRONMENT_VARIABLES
  const { myreservations, loading } = useSelector(selectReservations)

  const { pageable = { pageNumber: 0 }, last = false, empty = true } = {
    ...myreservations
  }
  const { pageNumber = 0 } = { ...pageable }

  const [bigData, setBigData] = useState<IBigDataContent[]>([])

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    // get initial reservations only if content is empty
    dispatch(
      myReservations({
        fromDate: moment().startOf('day').toISOString(),
        page: 0,
        size: pageSize,
        sort: ['dateStart', 'id']
      })
    )
  }, [dispatch, pageSize])

  useEffect(() => {
    const normalizedReservations = normalizeReservation(myreservations)
    const filteredReservations = filterReservations(
      'CANCELLED',
      normalizedReservations
    )
    const groupedByDay = groupReservationsByDay(filteredReservations)

    setBigData(groupedByDay)
  }, [myreservations])

  const handleLoadMore = (inView: boolean) => {
    if (inView && !last && !loading) {
      dispatch(
        myReservations({
          fromDate: moment().startOf('day').toISOString(),
          page: pageNumber + 1,
          size: pageSize,
          sort: ['dateStart', 'id']
        })
      )
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {bigData?.map((el, index) => (
          <ItemsGroup
            filter={filter}
            key={index}
            group={el}
            lastMonth={index + 1 === bigData.length}
            showReservationName={false}
          />
        ))}
        {!bigData.length && !loading && (
          <NoData
            title="Per ora sei in SmartWorking!"
            description="non vuoi stare casa? prenota ora"
          />
        )}
        {bigData.length > 0 && !empty && !last && (
          //TODO Ingore error detected by pipeline
          // @ts-ignore
          <InView as="div" onChange={inView => handleLoadMore(inView)}>
            <Loader visible={last} />
          </InView>
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(MyBooks)
