import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core'
import React, { VFC } from 'react'
import { useSelector } from 'react-redux'
import { selectShow } from '../../../store/reducers/LoaderReducer'

interface LoaderProps {
  visible?: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: 'fill-available',
    width: 'fill-available',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
    background: 'none',
    '& svg circle': {
      strokeWidth: '3px'
    }
  }
}))

const Loader: VFC<LoaderProps> = ({ visible }) => {
  const classes = useStyles()
  const show = useSelector(selectShow)

  return (
    (visible || show) && (
      <Grid
        container
        style={{ justifyContent: 'center' }}
        className={classes.root}
      >
        <CircularProgress
          variant="indeterminate"
          size={60}
          thickness={6}
          color="secondary"
          value={100}
        />
      </Grid>
    )
  )
}

export default Loader
