import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showToast } from 'store/reducers/ToastReducer'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { USER_CONTROLLER } from '../../constants/URLS'
import { ICreateUser, ISimpleUser, IUpdateUser } from '../../models/Auth.model'
import { IReservationsError } from 'models/Reservation.model'

export const getAllUsers = createAsyncThunk<
  ISimpleUser[],
  undefined,
  {
    rejectValue: AxiosResponse
  }
>('userControl/allUsers', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get<ISimpleUser[]>(
      USER_CONTROLLER.getUsers
    )
    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError
      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})

export const createUser = createAsyncThunk<
  any,
  ICreateUser,
  {
    rejectValue: AxiosResponse
  }
>(
  'userControl/addUser',
  async (requestBody: ICreateUser, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `${USER_CONTROLLER.createUser}`,
        {
          ...requestBody
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Utente creato con successo'
        })
      )
      dispatch(getAllUsers())
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              "Errore durante la creazione dell'utente"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)

export interface IDeleteParams {
  id: number
}

export const deleteUser = createAsyncThunk<
  any,
  IDeleteParams | undefined,
  {
    rejectValue: AxiosResponse
  }
>(
  'userControl/delete',
  async ({ id }: IDeleteParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.delete(
        `${USER_CONTROLLER.deleteUser}/${id}`
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Utente eliminato con successo'
        })
      )
      dispatch(getAllUsers())

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message: "Errore durante l'eliminazione dell'utente"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)

export interface IUpdateParams {
  id: number
  body: IUpdateUser
}

export const updateUser = createAsyncThunk<
  any,
  IUpdateParams,
  {
    rejectValue: AxiosResponse
  }
>(
  'userControl/update',
  async ({ id, body }: IUpdateParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.put(
        `${USER_CONTROLLER.updateUser}/${id}`,
        {
          ...body
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Utente aggiornato con successo'
        })
      )
      dispatch(getAllUsers())

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message: "Errore durante l'aggiornamento dell'utente"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
