import React, { useState, useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableRow
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Delete } from '@material-ui/icons'
import { Edit } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { Authority, ISimpleUser } from '../../../../models/Auth.model'
import { deleteUser } from '../../../../store/thunk/userController'
import CreationDialog from '../CreationDialog'

interface rowDetail {
  rowUser: ISimpleUser
  authorities: Authority[]
}
const TableRowUsers: React.FC<rowDetail> = ({ rowUser, authorities }) => {
  const [userEdit, setUserEdit] = useState({
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    enabled: false,
    authorities: []
  })

  useEffect(() => {
    setUserEdit({
      firstname: rowUser.firstname,
      lastname: rowUser.lastname,
      email: rowUser.email,
      username: rowUser.username,
      enabled: rowUser.enabled,
      authorities: rowUser.authorities.map(authority => authority.id)
    })
  }, [])

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => setOpenEdit(true)
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme: Theme) => ({
    tablecell: {
      fontWeight: 500,
      fontSize: '1.2rem',
      textAlign: 'center',
      width: '300px'
    },

    avatar: {
      background: '#ef7d44'
    },
    actionMenuItem: {
      '&:hover': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        backgroundColor: 'inherit'
      }
    },

    personaContainer: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center',
        padding: '5px'
      }
    },
    personalabel: {
      textAlign: 'start',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        display: 'none'
      }
    },
    salalabel: {
      width: 'auto',
      minWidth: 0,
      maxWidth: '200px',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    optionStatus: {
      '&:hover': {
        cursor: 'pointer'
      },
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(90deg)'
      },
      [theme.breakpoints.up('sm')]: {
        transform: 'rotate(0deg)'
      }
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      height: '200px',
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: '4px'
    }
  }))
  const classes = useStyles()

  return (
    <>
      <TableRow>
        <TableCell className={classes.tablecell}>
          <Typography variant="h6" color="initial">
            {rowUser.firstname}
          </Typography>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Typography variant="h6" color="initial">
            {rowUser.lastname}
          </Typography>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Typography variant="h6" color="initial">
            {rowUser.username}
          </Typography>
        </TableCell>
        <TableCell className={classes.tablecell}>
          <Typography variant="h6" color="initial">
            <Edit
              style={{ color: 'orange', cursor: 'pointer' }}
              onClick={() => {
                handleOpenEdit()
              }}
            ></Edit>
            <Delete
              style={{ color: 'DarkRed', cursor: 'pointer' }}
              onClick={() => {
                handleOpenDelete()
              }}
            ></Delete>
          </Typography>
        </TableCell>
      </TableRow>

      <CreationDialog
        editMode={true}
        user={userEdit}
        userId={rowUser.id}
        setUser={setUserEdit}
        authorities={authorities}
        open={openEdit}
        setOpen={setOpenEdit}
      />

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Elimina utente'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare l'utente {rowUser.username}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#2196f3' }} onClick={handleCloseDelete}>
            Annulla
          </Button>
          <Button
            style={{ color: '#2196f3' }}
            onClick={() => {
              handleCloseDelete()
              dispatch(
                deleteUser({
                  id: rowUser.id
                })
              )
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableRowUsers
