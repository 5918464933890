import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Authority } from '../../models/Auth.model'
import { getAuthorities } from '../thunk/authorithyController'
import { RootState } from '../configureStore'

interface IAuthorityControllerState {
  loading: boolean
  error: string
  authorities?: Authority[]
}

export const initialState: IAuthorityControllerState = {
  loading: false,
  error: undefined,
  authorities: undefined
}

export const authorityControllerSlice = createSlice({
  name: 'authorityControl',
  initialState,
  reducers: {
    setAuthorities: (state, action: PayloadAction<Authority[]>) => {
      state.authorities = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAuthorities.pending, state => {
        state.loading = true
      })
      .addCase(
        getAuthorities.fulfilled,
        (state, action: PayloadAction<Authority[]>) => {
          state.loading = false
          state.error = undefined
          state.authorities = action.payload
        }
      )
      .addCase(getAuthorities.rejected, (state, action) => {
        state.error = action.payload?.data
        state.loading = false
      })
  }
})

export const selectAuthorityControllerState = (
  state: RootState
): IAuthorityControllerState => state.authority

export const selectAuthorities = (state: RootState): Authority[] =>
  state.authority.authorities

export const selectLoading = (state: RootState): Boolean => state.user.loading

export default authorityControllerSlice.reducer
