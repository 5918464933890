import {
  Action,
  configureStore,
  getDefaultMiddleware,
  Middleware,
  ThunkAction
} from '@reduxjs/toolkit'
import authReducer from 'store/reducers/AuthReducer'
import authorityReducer from 'store/reducers/AuthorityReducer'
import formModalReducer from 'store/reducers/ConfirmSeatModalReducer'
import loaderReducer from 'store/reducers/LoaderReducer'
import modalReducer from 'store/reducers/ModalReducer'
import reservationsReducer from 'store/reducers/ReservationsReducer'
import toastReducer from 'store/reducers/ToastReducer'
import userControllerReducer from 'store/reducers/userControllerReducer'
import groupControllerReducer from 'store/reducers/groupControllerReducer'
import iconReducer from 'store/reducers/iconReducer'
import RedirectModalReducer from 'store/reducers/RedirectModalReducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import UserIconReducer from './reducers/UserIconReducer'
import ErrorActionSlice from './reducers/ErrorActionReducer'

// AUTH STATE
const setAuthState = () => {
  try {
    const auth = store.getState()?.auth

    localStorage.setItem('auth', JSON.stringify(auth) || '')
  } catch (e) {
    return undefined
  }
}

const getAuthState = () => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth') || '')

    return {
      logged: auth.logged,
      user: auth.user,
      error: auth.error,
      loading: auth.loading,
      details: auth.details
    }
  } catch (e) {
    return {
      logged: false,
      loading: false,
      error: undefined,
      user: undefined,
      details: undefined
    }
  }
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    authority: authorityReducer,
    reservations: reservationsReducer,
    user: userControllerReducer,
    group: groupControllerReducer,
    loader: loaderReducer,
    modal: modalReducer,
    formModal: formModalReducer,
    toast: toastReducer,
    icon: iconReducer,
    userIcon: UserIconReducer,
    redirectModal: RedirectModalReducer,
    errorAction: ErrorActionSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {
    //TODO ignore error detected by pipeline
    // @ts-ignore
    auth: { ...getAuthState() }
  }
})

store.subscribe(() => setAuthState())

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
