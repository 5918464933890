/**
 *
 * LoadingBar
 *
 */
import { createStyles, withStyles } from '@material-ui/core'
import { activatePace } from './pace'

interface Props {
  color: string
}

const styles = createStyles({
  '@global': {
    '.pace': {
      pointerEvents: 'none',
      userSelect: 'none'
    },
    '.pace-inactive': {
      display: 'none'
    },
    '.pace .pace-progress': {
      background: (props: Props) => props.color || null,
      position: 'fixed',
      zIndex: 2000,
      top: 0,
      right: '100%',
      width: '100%',
      height: 3
    }
  }
})

function unstyledLoadingBar() {
  // activatePace.call(this);
  activatePace.call(window)
  return null
}

export const LoadingBar = withStyles(styles)(unstyledLoadingBar)

declare global {
  interface Window {
    paceOptions: any
  }
}
/**
 * Pace has defaultly only GET requests enabled. Enable for POST requets too.
 */
window.paceOptions = {
  ajax: {
    trackMethods: ['GET', 'POST']
  }
}
