import moment from 'moment'
moment.locale('it')

type ExportType = 'lowercase' | 'uppercase'

const exportTypeMap: Map<string, (s: string) => string> = new Map([
  ['lowercase', s => s.toLowerCase()],
  ['uppercase', s => s.toUpperCase()]
])

export const getMonthNameByMonthNumber = (
  monthNumber: number,
  exportType: ExportType
): string => {
  const monthName = moment({ month: monthNumber }).format('MMM')
  return exportTypeMap.get(exportType)(monthName)
}

export const getDayNameByDate = (
  date: string,
  exportType: ExportType
): string => {
  const dayName = moment(date).format('ddd')
  return exportTypeMap.get(exportType)(dayName)
}
