import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { selectAuthState } from '../../store/reducers/AuthReducer'
import { RESERVATIONS_PATH } from '../routes/constants'
const AlreadyAuthenticatedGuard: React.FC<{ children: JSX.Element }> = ({
  children
}) => {
  const authState = useSelector(selectAuthState)

  return (
    <>
      {authState?.user?.expires_date &&
      moment(authState.user.expires_date).isAfter(moment()) ? (
        <Navigate to={`/${RESERVATIONS_PATH}`} replace={true} />
      ) : (
        children
      )}
    </>
  )
}

export default AlreadyAuthenticatedGuard
