/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations'
import { _t } from 'utils/messages'

export const messages = {
  title: () => _t(translations.LoginPage.title, 'Sign in'),
  form: {
    email: () => _t(translations.LoginPage.form.labels.email, 'Email Address'),
    password: () => _t(translations.LoginPage.form.labels.password, 'Password'),
    remember: () =>
      _t(translations.LoginPage.form.labels.remember, 'Remember me'),
    btnConfirm: () =>
      _t(translations.LoginPage.form.labels.btnConfirm, 'Sign In'),
    btnForgotPassword: () =>
      _t(translations.LoginPage.form.btn.forgotPassword, 'Forgot password?'),
    register: () =>
      _t(
        translations.LoginPage.form.btn.register,
        "Don't have an account? Sign Up"
      )
  }
}
