import { makeStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const UseStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& img': {
      width: '30rem',
      [theme.breakpoints.down('md')]: {
        width: '20rem'
      }
    }
  }
}))
const index: React.VFC<{ title: string; description: string }> = ({
  title,
  description = ''
}) => {
  const classes = UseStyles()
  return (
    <Box className={classes.root}>
      <img src="/smartworking.png" alt="People illustrations by Storyset" />
      <Typography variant="h3">{title}</Typography>
      <Typography variant="subtitle2">{description}</Typography>
    </Box>
  )
}

export default index
