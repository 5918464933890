import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core'
import EventSeatRoundedIcon from '@material-ui/icons/EventSeatRounded'
import {
  APPROVAL_BOOKING_PATH,
  DETAILS_TABLE_RESERVATION,
  RESERVATIONS_PATH
} from 'app/routes/constants'
import { SegmentedControl } from 'ios-segmented-control-react'
import { AuthorityNames, ISimpleUser } from 'models/Auth.model'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { closeModal, IModal } from 'store/reducers/ModalReducer'
import {
  closeRedirectModal,
  IRedirectAction,
  openRedirectModal
} from 'store/reducers/RedirectModalReducer'
import { showToast } from 'store/reducers/ToastReducer'
import { countSeats } from 'store/thunk/countSeatsOnDates'
import { createGroupRecursiveReservation } from 'store/thunk/createGroupRecursiveReservation'
import { createGroupGenericReservation } from 'store/thunk/createGroupReservationGeneric'
import { createRecursiveReservation } from 'store/thunk/createRecursiveReservation'
import { createGenericReservation } from 'store/thunk/createReservationGeneric'
import { createSpecificReservation } from 'store/thunk/createSpecificReservations'
import {
  findAvailableSeats,
  IFindAvailableSeatsParams
} from 'store/thunk/findAvailableSeats'
import { myReservationsCalendar } from 'store/thunk/myReservationsCalendar'
import { getAllUsers } from 'store/thunk/userController'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import {
  IGenericReservationParams,
  IRecurringReservation,
  ISeat,
  ISpecificReservationParams,
  TRecurringGroupReservation
} from '../../../models/Reservation.model'
import { selectDetails, selectUser } from '../../../store/reducers/AuthReducer'
import {
  selectMyReservations,
  selectReservations
} from '../../../store/reducers/ReservationsReducer'
import { selectUsers } from '../../../store/reducers/userControllerReducer'
import { IMode } from '../Booking/Booking'
import { FlexDiv } from '../Booking/Booking.Styled'
import {
  BookingCalendar,
  MyBadge,
  StyledContainer
} from '../Calendar/Calendar.styled'
import LegendaCalendar from '../Calendar/LegendaCalendar'
import LegendaPrenotazioni from '../Calendar/LegendaPrenotazioni'
import { StyledSegmentedControlContainer } from './ModalContent.styled'

const DAYSOFWEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']

const getDayOfWeek = (date: Date): string[] => {
  return [DAYSOFWEEK[date.getDay() - 1]]
}

export const checkPremission = (
  authority_names: AuthorityNames[],
  who: AuthorityNames
) => {
  return authority_names.includes(who)
}

const useStyles = makeStyles((theme: Theme) => ({
  infoCalendar: { margin: 'auto' },
  segmento: {
    '& div ul li': {
      boxShadow: 'none !important'
    }
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridAutoRows: 'minmax(min-content, max-content)',
    gap: '0px 0px',
    gridTemplateAreas: `"reservation-header reservation-header reservation-header reservation-header"
    "reservation-type reservation-type reservation-type reservation-type"
    "reservation-recursion reservation-recursion reservation-recursion reservation-recursion"
    "reservation-seats reservation-seats reservation-seats reservation-seats"
    "reservation-people reservation-people reservation-people reservation-people"
    "reservation-footer reservation-footer reservation-footer reservation-footer"`,
    width: '500px',
    aspectRatio: 'auto',
    backgroundColor: '#fff',

    borderRadius: '15px',
    margin: '0 auto',
    padding: '20px 40px',
    '& h4': {
      fontWeight: 500
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      padding: '20px 10px'
    }
  },
  reservationHeader: {
    gridArea: 'reservation-header'
  },
  reservationType: {
    gridArea: 'reservation-type'
  },
  reservationPeople: {
    gridArea: 'reservation-people',
    '&:empty': {
      display: 'none'
    }
  },
  reservationSeats: { gridArea: 'reservation-seats' },
  reservationRecursion: {
    gridArea: 'reservation-recursion'
  },
  reservationFooter: {
    gridArea: 'reservation-footer',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    borderTop: '1px solid #efeef5',
    paddingTop: '8px',
    marginTop: '16px'
  },
  reservationTypeForm: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  confirmButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      filter: 'brightness(0.85)',
      boxShadow: 'none'
    }
  },
  cancelButton: {
    backgroundColor: '#efeef5',
    color: '#717171',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#efeef5',
      filter: 'brightness(0.85)',
      boxShadow: 'none'
    }
  },
  formControl: {
    margin: '30px 8px',
    width: '100%',
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#717171'
    }
  },
  selectChip: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff'
    },
    '& .MuiInput-underline': {
      border: 'none !important'
    },
    '& .MuiInput-underline:hover': {
      border: 'none !important'
    },
    '& .MuiInput-underline:before': {
      border: 'none !important'
    },
    '& .MuiInput-underline:after': {
      border: 'none !important'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #c4bfbf',
    borderRadius: '15px',
    alignItems: 'center',
    gap: '5px'
  },
  chip: {
    margin: 2,
    color: '#717171',

    backgroundColor: '#f4f3f6',
    padding: '0 20px',
    '& svg': {
      border: '2px solid #717171',
      background: '#717171',
      borderRadius: '50%',
      padding: '3px',
      color: '#fff'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 15px'
    }
  },
  recursiveFormControl: {
    width: '100%',
    '& h6': {
      color: '#717171',
      fontWeight: 100
    }
  },
  formLabel: {
    color: '#717171',
    fontWeight: 100
  },
  avatarUserInitials: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff !important'
  }
}))

//const SIMPLE_USER_VISIBLE_MONTHS = 2
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function ModalContent() {
  const { pageSize } = ENVIRONMENT_VARIABLES
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { authority_names } = useSelector(selectUser)
  const details = useSelector(selectDetails)
  const { numberOfSeats, seats } = useSelector(selectReservations)
  const users = useSelector(selectUsers)
  const content = useSelector(selectMyReservations)?.content || []
  const { myreservations, loading } = useSelector(selectReservations)
  const { pageable = { pageNumber: 0 }, last = true } = {
    ...myreservations
  }

  const [mineOthersMap] = useState(
    new Map([
      ['mine', { mine: true, others: false }],
      ['others', { mine: false, others: true }]
    ])
  )
  const [availableSeats, setAvailableSeats] = useState<ISeat[]>([])
  const [showCalendar, setShowCalendar] = useState<boolean>(true)
  const [type, setType] = useState<IMode>()
  const [recursive, setRecursive] = useState(false)
  const [val, setVal] = useState<string[]>([])
  const [checks, setChecks] = useState({
    mine: true,
    others: false
  })
  const [user, setUser] = useState<ISimpleUser[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const [activeDate, setActiveDate] = useState(new Date())
  const [numberOfOccurrence, setNumberOfOccurrence] = useState(1)
  const [selectedSeat, setSelectedSeat] = useState<ISeat[]>([])
  const [selectedSeatIds, setselectedSeatIds] = useState([])
  const [viewCalendar, setViewCalendar] = useState(
    moment().startOf('day').toISOString()
  )

  const simpleUser = useCallback(
    () => authority_names.length === 1 && authority_names[0] === 'ROLE_USER',
    [authority_names]
  )

  useEffect(() => {
    moment.locale('it')
  }, [])

  useEffect(() => {
    if (!simpleUser()) {
      dispatch(getAllUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleUser])

  useEffect(() => {
    if (seats) {
      setAvailableSeats(seats)
    }
  }, [seats, val])

  useEffect(() => {
    if (!simpleUser()) {
      let startDate = moment(val[0]).startOf('day').toISOString()
      let endDate = moment(val[0]).endOf('day').toISOString()

      const params = {
        dateStart: startDate,
        dateEnd: endDate,
        numberOfOccurrence: 1,
        recurrenceRangeType: 'NUMBERED',
        recurrenceTimeZone: 'Europe/Rome',
        recurringReservation: false,
        timeReservation: 539
      } as IFindAvailableSeatsParams
      dispatch(findAvailableSeats(params))
    }
  }, [checks.others, dispatch, recursive, simpleUser, val])

  useEffect(() => {
    setAvailableSeats([])
    setRecursive(false)
    setType(null)
    const myreservationCalendar = async () => {
      await dispatch(
        myReservationsCalendar({
          fromDate: `${
            moment(viewCalendar).format('MM-YYYY') ===
            moment(new Date().toISOString()).format('MM-YYYY')
              ? moment().startOf('day').toISOString()
              : moment(viewCalendar).startOf('day').toISOString()
          }`,
          toDate: `${
            viewCalendar
              ? moment(viewCalendar).endOf('month').toISOString()
              : moment().endOf('month').toISOString()
          }`,
          page: 0,
          size: pageSize,
          sort: ['dateStart', 'id'],
          showCancelled: true
        })
      )
    }

    const seatAvailable = async () => {
      await dispatch(
        countSeats({
          fromDate: moment(activeDate).startOf('D').toISOString(),
          toDate: moment(activeDate).endOf('month').toISOString()
        })
      )
    }
    const data = async () => {
      await myreservationCalendar()
      await seatAvailable()
    }
    data()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewCalendar])

  useEffect(() => {
    if (!last && !loading) {
      dispatch(
        myReservationsCalendar({
          fromDate: `${
            viewCalendar
              ? moment(viewCalendar).startOf('day').toISOString()
              : moment().startOf('day').toISOString()
          }`,
          toDate: `${
            viewCalendar
              ? moment(viewCalendar).endOf('month').toISOString()
              : moment().endOf('month').toISOString()
          }`,
          page: pageable.pageNumber + 1,
          size: pageSize,
          sort: ['dateStart', 'id'],
          showCancelled: true
        })
      )
    }
  }, [myreservations])

  const handleDialogClose = () => {
    setNumberOfOccurrence(1)
    setVal([])

    setChecks({ mine: true, others: false })
    setSelectedSeat([])
    setselectedSeatIds([])
    setUser([])
    setSelectedUserIds([])
    setShowCalendar(true)
    setRecursive(false)
    dispatch(closeModal())
  }

  const goToPrenotazioni = () => {
    navigate(`/${RESERVATIONS_PATH}`, { replace: true })

    dispatch(closeModal())
  }

  const handleChangeChip = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string[]
    if (
      !checks.others &&
      selectedSeatIds.length === 1 &&
      selectedSeatIds.some(pn => newValue.includes(pn))
    ) {
      dispatch(
        showToast({ type: 'warning', message: 'seleziona solo un posto' })
      )
      return
    }
    setselectedSeatIds(event.target.value as string[])
    const seatIds = event.target.value as string[]
    const fullSeats = seatIds.map(seatId =>
      seats.find(el => el.id - Number(seatId) === 0)
    )
    setSelectedSeat([...fullSeats])
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUserIds(event.target.value as string[])
    const userIds = event.target.value as string[]
    const fullUsers = userIds.map(seatId =>
      users.find(el => el.id - Number(seatId) === 0)
    )
    setUser([...fullUsers])
  }

  const getChipLabelFromSeatId = useCallback(
    (id: number): string => {
      const found = seats.find(el => el.id - id === 0)
      return found ? `${found?.room?.name} ${found?.description}` : ''
    },
    [seats]
  )

  const getChipLabelFromUserId = useCallback(
    (id: number): string => {
      const found = users.find(el => el.id - id === 0)
      return `${found.firstname} ${found.lastname}`
    },
    [users]
  )

  const getUserInitialsFromUserId = useCallback(
    (id: number): string => {
      const fullName = getChipLabelFromUserId(id)
      const split = fullName.split(' ')
      return (split[0][0] + split[1][0]).toUpperCase()
    },
    [getChipLabelFromUserId]
  )

  const handleChooseAction = (date: string) => {
    if (
      checkPremission(authority_names, 'ROLE_GROUP_ADMIN') ||
      checkPremission(authority_names, 'ROLE_GROUP_SUPERVISOR') ||
      checkPremission(authority_names, 'ROLE_USER_SUPERVISOR') ||
      checkPremission(authority_names, 'ROLE_ADMIN')
    ) {
      const parsedUSDate = new Date(date)
        .toLocaleDateString('en-ZA', {
          // gives YYYY-MM-DD format
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
        .replace(/\//g, '-')
        .replace(/,/, '')
      const actions: IRedirectAction[] = [
        {
          label: 'Prenota',
          icon: 'book_online',
          callback: async () => {
            await dispatch(closeRedirectModal())
            handleDateChange(date)
          }
        },
        {
          label: 'Vai a lista prenotazioni',
          icon: 'table_chart',
          callback: async () => {
            await dispatch(closeRedirectModal())
            navigate(
              `/${RESERVATIONS_PATH}/${APPROVAL_BOOKING_PATH}/${DETAILS_TABLE_RESERVATION}/${parsedUSDate}`
            )
          }
        },
        {
          label: 'Chiudi',
          icon: 'close',
          callback: async () => {
            await dispatch(closeRedirectModal())
          }
        }
      ]
      const parsedDate = new Date(date).toLocaleDateString('it-IT', {
        day: 'numeric',
        month: 'long'
      })
      dispatch(
        openRedirectModal({
          title: `Approva o prenota per il ${parsedDate}`,
          actions,
          selectedDate: date
        })
      )
    } else {
      handleDateChange(date)
    }
  }
  const handleDateChange = (date: string) => {
    setCurrentDate(new Date(date))

    const formattedDate =
      date.length > 0 ? date : moment(date).format('yyyy-MM-DD')
    if (!recursive) {
      setVal([formattedDate])
    } else {
      if (val.length === 2) {
        setVal([formattedDate])
      } else {
        let index = val.findIndex(el => el === formattedDate)
        if (index >= 0) {
          let obj = val
          obj.splice(index, 1)
          setVal(obj)
        } else {
          setVal([...val, formattedDate])
        }
      }
    }

    setShowCalendar(false)
  }

  const handleRecursive = (reservationType: string) => {
    setAvailableSeats([])
    setRecursive(reservationType === 'recursive')
  }
  const handleOthers = (reservationType: string) => {
    setChecks(mineOthersMap.get(reservationType))
  }
  const getSeriesName = (t?: string) => {
    switch (t || type) {
      case 'DAILY':
        return 'daily_numbered'
      case 'ABS_MONTHLY':
        return 'monthly_numbered'
      default:
        return 'weekly_numbered'
    }
  }

  const createRecurringReservation = () => {
    let startDate = moment(val[0]).startOf('day').toISOString()
    const modal: IModal = {
      title: 'Prenotazione Creata Con Successo',
      content: "Desideri Effettuare Un'altra Prenotazione?",
      open: true,
      actions: [
        {
          callback: goToPrenotazioni,
          label: 'No',
          secondaryButton: true
        },
        {
          callback: handleDialogClose,
          label: 'Si'
        }
      ]
    }
    if (checks.mine) {
      const params: IRecurringReservation = {
        dateStart: startDate,
        recurringReservation: true,
        timeReservation: 539,
        recurrencePatternType: type,
        recurrenceTimeZone: 'Europe/Rome',
        recurrenceRangeType: 'NUMBERED',
        seriesName: getSeriesName(),
        user: details,
        seat: selectedSeat[0],
        numberOfOccurrence
      }
      switch (type) {
        case 'WEEKLY':
          params.daysOfWeek = getDayOfWeek(new Date(val[0]))
          break
        case 'ABS_MONTHLY':
          params.dayOfMonth = moment(startDate).date()
          break
        default:
          break
      }

      dispatch(createRecursiveReservation({ params, modal }))
    } else if (checks.others) {
      const params = {
        dateStart: startDate,
        recurringReservation: true,
        timeReservation: 539,
        recurrencePatternType: type,
        recurrenceTimeZone: 'Europe/Rome',
        recurrenceRangeType: 'NUMBERED',
        seriesName: getSeriesName(),
        users: [...user],
        seats: [...selectedSeat],
        numberOfOccurrence
      } as TRecurringGroupReservation
      switch (type) {
        case 'WEEKLY':
          params.daysOfWeek = getDayOfWeek(new Date(val[0]))
          break
        case 'ABS_MONTHLY':
          params.dayOfMonth = moment(startDate).date()
          break
        default:
          break
      }
      dispatch(createGroupRecursiveReservation({ params, modal }))
    }
  }

  const handleRecursiveChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setselectedSeatIds([])
    setSelectedSeat([])
    const t: IMode = event.target.value as IMode
    setType(t)

    if (numberOfOccurrence) {
      let startDate = moment(val[0]).startOf('day').toISOString()
      const params: IFindAvailableSeatsParams = {
        dateStart: startDate,
        numberOfOccurrence,
        recurrencePatternType: t,
        recurrenceRangeType: 'NUMBERED',
        recurrenceTimeZone: 'Europe/Rome',
        recurringReservation: recursive,
        seriesName: getSeriesName(t),
        timeReservation: 539
      }

      switch (t) {
        case 'WEEKLY':
          params.daysOfWeek = getDayOfWeek(new Date(startDate))
          break
        case 'ABS_MONTHLY':
          params.dayOfMonth = moment(startDate).date()
          break
        default:
          break
      }

      dispatch(findAvailableSeats(params))
    }
  }

  const GenericReservation = () => {
    const dateStart = moment(val[0]).startOf('day').toISOString()
    const dateEnd = moment(val[0]).endOf('day').toISOString()
    const modal: IModal = {
      title: "Desideri Effettuare un'altra Prenotazione?",
      open: true,
      actions: [
        {
          callback: goToPrenotazioni,
          label: 'No',
          secondaryButton: true
        },
        {
          callback: handleDialogClose,
          label: 'Si'
        }
      ]
    }
    if (checks.mine) {
      const obj: IGenericReservationParams = {
        dateEnd,
        dateStart,
        user: details
      }
      const objspecific: ISpecificReservationParams = {
        dateEnd,
        dateStart,
        user: details,
        seat: selectedSeat[0]
      }

      if (!simpleUser()) {
        if (selectedSeat.length > 0) {
          dispatch(createSpecificReservation({ params: objspecific, modal }))
        } else {
          dispatch(
            showToast({ type: 'warning', message: 'Devi selezionare un posto' })
          )

          return
        }
      }

      dispatch(createGenericReservation({ params: obj, modal }))
    } else if (checks.others) {
      const obj = {
        dateEnd,
        dateStart,
        seats: [...selectedSeat],
        users: [...user]
      }

      dispatch(createGroupGenericReservation({ params: obj, modal }))
    }
  }

  const handleDisabled = useMemo(() => {
    if (!recursive) {
      if (simpleUser()) {
        return !val[0]
      } else {
        return !(
          val[0] &&
          (checks.mine ? true : selectedSeat.length) &&
          (checks.others ? user.length : details)
        )
      }
    } else if (recursive && type === 'WEEKLY') {
      return !(
        val[0] &&
        selectedSeat.length &&
        (checks.others ? user.length : details)
      )
    } else {
      return !(
        val[0] &&
        numberOfOccurrence &&
        selectedSeat.length &&
        (checks.others ? user.length : details) &&
        type
      )
    }
  }, [
    checks.mine,
    checks.others,
    details,
    numberOfOccurrence,
    recursive,
    selectedSeat,
    simpleUser,
    type,
    user,
    val
  ])

  const onSelectChange = e => {
    setNumberOfOccurrence(Number.parseInt(e.target.value as string))
    let startDate = moment(val[0]).startOf('day').toISOString()

    const params: IFindAvailableSeatsParams = {
      dateStart: startDate,
      numberOfOccurrence: Number.parseInt(e.target.value as string),
      recurrencePatternType: type,
      recurrenceRangeType: 'NUMBERED',
      recurrenceTimeZone: 'Europe/Rome',
      recurringReservation: recursive,
      seriesName: getSeriesName(),
      timeReservation: 539
    }

    switch (type) {
      case 'WEEKLY':
        params.daysOfWeek = getDayOfWeek(new Date(startDate))
        dispatch(findAvailableSeats(params))
        break
      case 'ABS_MONTHLY':
        params.dayOfMonth = moment(startDate).date()
        dispatch(findAvailableSeats(params))
        break
      default:
        break
    }
  }

  const tileDisabled = ({ date, view }) => {
    const day = date.getDay()

    if (view === 'year') return false

    if (day === 6 || day === 0) {
      return true
    } else if (simpleUser()) {
      let d = moment(date).format('yyyy-MM-DD')
      content.forEach(el => {
        const status = el.reservationStatus.status
        const statuses = ['PENDING', 'ACCEPTED', 'REJECTED', 'NEW']
        if (
          moment(el.dateStart).format('yyyy-MM-DD') === d &&
          statuses.includes(status)
        ) {
          return true
        }
      })
    }
    return false
  }

  const renderModalCalendar = () => (
    // TODO ignore error detected by pipeline
    // @ts-ignore
    <BookingCalendar
      minDate={new Date()}
      defaultValue={currentDate}
      onChange={handleChooseAction}
      tileDisabled={view => tileDisabled(view)}
      minDetail="year"
      onActiveStartDateChange={({ activeStartDate, view }) => {
        if (view === 'month') {
          setActiveDate(new Date(activeStartDate))
          setViewCalendar(activeStartDate)
        }
      }}
      tileClassName={({ date }) => {
        let className = 'react-calendar__tile'
        let d = moment(date).format('yyyy-MM-DD')

        if (val.findIndex(el => el === d) >= 0) {
          className += '--active'
        }

        content.forEach(el => {
          if (moment(el.dateStart).format('yyyy-MM-DD') === d) {
            switch (el.reservationStatus.status) {
              case 'PENDING':
                className += '--day-full pending'
                return
              case 'ACCEPTED':
                className += '--day-full accepted'
                return
              case 'REJECTED':
                className += '--day-full rejected'
                return
              case 'NEW':
                className += '--day-full new'
                return
              case 'CANCELLED':
                className += '--day-full cancelled'
                return
            }
          }
        })

        return className
      }}
      tileContent={({ date }) => {
        if (numberOfSeats) {
          let tmp = new Date()
          tmp.setTime(0)
          if (tmp <= date) {
            return Object.entries(numberOfSeats).map(([k, v], i) => {
              let m = k.substr(0, 10)
              let d = moment(date).format('yyyy-MM-DD')
              if (m === d) {
                return (
                  <MyBadge
                    key={i}
                    badgeContent={v === 0 ? '0' : v}
                    style={{ color: v > 3 ? 'green' : '#d9534f' }}
                  />
                )
              }
              return null
            })
          }
        }
      }}
      showNeighboringMonth={false}
    />
  )

  const renderOthers = () => (
    <Box className={classes.reservationPeople}>
      <FormControl className={classes.formControl} style={{ margin: '15px 0' }}>
        <InputLabel id="reservationPeople-mutiple-chip-label">
          Prenota per
        </InputLabel>
        <Select
          labelId="reservationPeople-mutiple-chip-label"
          id="reservationPeople-mutiple-chip"
          className={classes.selectChip}
          multiple
          value={selectedUserIds}
          onChange={handleChange}
          input={<Input id="select-multiple-chip-reservationPeople" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as string[]).map(value => (
                <Chip
                  avatar={
                    <Avatar className={classes.avatarUserInitials}>
                      {getUserInitialsFromUserId(Number(value))}
                    </Avatar>
                  }
                  key={value}
                  label={getChipLabelFromUserId(Number(value))}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {users?.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {`${user?.firstname} ${user?.lastname}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )

  const renderMineAndOthers = () =>
    (checkPremission(authority_names, 'ROLE_GROUP_ADMIN') ||
      checkPremission(authority_names, 'ROLE_GROUP_SUPERVISOR') ||
      checkPremission(authority_names, 'ROLE_ADMIN')) && (
      //TODO ignore error detected by pipeline
      // @ts-ignore
      <StyledSegmentedControlContainer className={classes.segmento}>
        <SegmentedControl
          segments={[
            { label: 'Per me', value: 'mine', default: true },
            { label: 'Per altri', value: 'others' }
          ]}
          onChangeSegment={newValue => handleOthers(newValue)}
        />
      </StyledSegmentedControlContainer>
    )

  const renderSeatsSelection = () => (
    <Box className={classes.reservationSeats}>
      <FormControl className={classes.formControl} style={{ margin: '15px 0' }}>
        <InputLabel id="demo-mutiple-chip-label">
          Scegli il piano e posti
        </InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          className={classes.selectChip}
          multiple
          value={selectedSeatIds}
          onChange={handleChangeChip}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as string[]).map(value => (
                <Chip
                  icon={<EventSeatRoundedIcon />}
                  key={value}
                  label={getChipLabelFromSeatId(Number(value))}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {availableSeats.map(seat => (
            <MenuItem key={seat.id} value={seat.id}>
              {`${seat?.room?.name} ${seat.description}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )

  const renderRecursiveForm = () => (
    <Grid container spacing={3} className={classes.reservationRecursion}>
      <Typography
        variant="subtitle1"
        className={classes.formLabel}
        style={{ marginTop: 15, marginLeft: 8 }}
      >
        Seleziona modalità di ripetizione
      </Typography>
      <Grid item xs={12} md={12}>
        <FormControl className={classes.recursiveFormControl}>
          <InputLabel id="demo-simple-select-label">
            Freq. ripetizione
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type ?? ''}
            onChange={handleRecursiveChange}
          >
            <MenuItem value="DAILY">Giornaliera</MenuItem>
            <MenuItem value="WEEKLY">
              Settimanale ogni {moment(val[0]).format('dddd')}
            </MenuItem>
            <MenuItem value="ABS_MONTHLY">
              Mensile ogni {moment(val[0]).format('D')} lavorativo del mese
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <InputLabel id="number-of-occurrence">N. ripetizioni</InputLabel>
        <Select
          fullWidth
          placeholder="N. Occorrenze"
          disabled={!val[0]}
          labelId="number-of-occurrence"
          value={numberOfOccurrence}
          onChange={onSelectChange}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el, i) => (
            <MenuItem key={i} value={el}>
              {el} {el > 1 ? 'volte' : 'volta'}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )

  const renderSigleAndRecursive = () => (
    //TODO ignore error detected by pipeline
    // @ts-ignore
    <StyledSegmentedControlContainer className={classes.segmento}>
      <SegmentedControl
        segments={[
          { label: 'Singola', value: 'single' },
          {
            label: 'Ricorsiva',
            value: 'recursive',
            disabled: !!authority_names.includes('ROLE_GROUP_ADMIN')
          }
        ]}
        onChangeSegment={newValue => handleRecursive(newValue)}
      />
    </StyledSegmentedControlContainer>
  )

  return (
    <>
      {!val || showCalendar ? (
        <>
          <StyledContainer calendar={showCalendar}>
            {showCalendar && renderModalCalendar()}
          </StyledContainer>

          <LegendaCalendar />
        </>
      ) : (
        <>
          <Box className={classes.container}>
            <Box className={classes.reservationHeader}>
              <Typography variant="h4">Prenota una postazione</Typography>
              <FlexDiv
                flexDirection="row"
                style={{ justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ marginTop: 5, marginRight: 15 }}
                >
                  Data selezionata : {moment(val[0]).format('DD MMMM')}
                </Typography>
              </FlexDiv>
            </Box>
            <Box className={classes.reservationType}>
              {!simpleUser() && (
                <>
                  <Typography
                    variant="subtitle1"
                    className={classes.formLabel}
                    style={{ marginTop: 15 }}
                  >
                    Tipologia di prenotazione
                  </Typography>
                  <Box className={classes.reservationTypeForm}>
                    {!simpleUser() && renderSigleAndRecursive()}
                    {renderMineAndOthers()}
                  </Box>
                </>
              )}
            </Box>
            {recursive && renderRecursiveForm()}
            {seats && !simpleUser() && renderSeatsSelection()}
            {checks.others && renderOthers()}
            <Box className={classes.reservationFooter}>
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={() => setShowCalendar(true)}
              >
                Annulla
              </Button>
              <Button
                className={classes.confirmButton}
                variant="contained"
                disabled={handleDisabled}
                onClick={
                  recursive ? createRecurringReservation : GenericReservation
                }
              >
                Conferma
              </Button>
            </Box>
          </Box>
          {!simpleUser() && <LegendaPrenotazioni />}
        </>
      )}
    </>
  )
}
