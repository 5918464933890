import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectModalState } from '../../../store/reducers/ModalReducer'
import styled from 'styled-components/macro'

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 25px;
  }
`

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`

const StyledButton = styled(Button)`
  border-radius: 15px;

  :hover {
    transform: translateY(-2px);
  }
`

const MyDialog = () => {
  const { open, title, content, closeCallback, actions } = useSelector(
    selectModalState
  )

  return (
    <StyledDialog
      open={open}
      onClose={closeCallback}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <StyledDialogActions>
        {actions?.map(action => (
          <StyledButton
            key={action.label}
            onClick={action.callback}
            color={!action.secondaryButton ? 'primary' : 'secondary'}
          >
            {action.label}
          </StyledButton>
        ))}
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default React.memo(MyDialog)
