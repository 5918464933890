import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'

interface ILoaderReducer {
  show?: boolean
}

const initialState: ILoaderReducer = {
  show: false
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    show(state) {
      state.show = true
    },
    hide(state) {
      state.show = false
    }
  }
})

export const selectShow = (state: RootState): boolean => !!state.loader.show

export const { show, hide } = loaderSlice.actions

export default loaderSlice.reducer
