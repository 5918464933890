import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'

export interface IFormModal {
  title: string
  open: boolean
  reservationId: number
}

const initialState: IFormModal = {
  title: '',
  open: false,
  reservationId: null
}

export const formModalSlice = createSlice({
  name: 'formModal',
  initialState,
  reducers: {
    openFormModal(state, action: PayloadAction<Omit<IFormModal, 'open'>>) {
      state.title = action.payload.title
      state.open = true
      state.reservationId = action.payload.reservationId
    },
    closeFormModal(state) {
      state.title = ''
      state.open = false
    }
  }
})

export const selectFormModalState = (state: RootState): IFormModal =>
  state.formModal

export const { openFormModal, closeFormModal } = formModalSlice.actions

export default formModalSlice.reducer
