import ToApprove from 'app/components/Booking/ToApprove'
import { useRoutes } from 'react-router-dom'
import { NotFoundPage } from '../components'
import Booking from '../components/Booking/Booking'
import GroupReservations from '../components/Booking/GroupReservations'
import ModalContent from '../components/ModalContent/ModalContent'
import AlreadyAuthenticatedGuard from '../guards/AlreadyAuthenticatedGuard'
import RoleGuard from '../guards/RoleGuard'
import MainLayout from '../layout/MainLayout'
import AuthLayout from '../layout/AuthLayout'
import MinimalLayout from '../layout/MinimalLayout'
import LoginPage from '../pages/LoginPage'
import DayReservationDetail from '../components/ReservationTableDay/index'
import Users from '../components/Users/Users'
import Groups from '../components/Groups/Groups'
import React from 'react'

import {
  ADD_RESERVATION_PATH,
  APPROVAL_BOOKING_PATH,
  GROUP_BOOKING_PATH,
  USER_PATH,
  GROUP_PATH,
  DETAILS_TABLE_RESERVATION
} from './constants'

const Routes = () => {
  const AuthenticationRoutes = {
    path: '',
    element: (
      <AlreadyAuthenticatedGuard>
        <MinimalLayout />
      </AlreadyAuthenticatedGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <AuthLayout>
            <LoginPage />
          </AuthLayout>
        )
      },
      { path: '*', element: <NotFoundPage /> }
    ]
  }
  const MainRoutes = {
    path: 'reservations',
    element: (
      <RoleGuard>
        <MainLayout />
      </RoleGuard>
    ),
    children: [
      { path: '', element: <Booking /> },
      { path: `${ADD_RESERVATION_PATH}`, element: <ModalContent /> },

      {
        path: `${GROUP_BOOKING_PATH}`,
        element: <GroupReservations filter="NONE" />
      },
      {
        path: `${USER_PATH}`,
        element: <Users />
      },
      {
        path: `${GROUP_PATH}`,
        element: <Groups />
      },
      {
        path: `${APPROVAL_BOOKING_PATH}`,
        element: <ToApprove filter="NONE" />
      },

      {
        path: `${APPROVAL_BOOKING_PATH}/${DETAILS_TABLE_RESERVATION}/:day`,
        element: <DayReservationDetail />
      },
      { path: '*', element: <NotFoundPage /> }
    ]
  }

  const routing = useRoutes([AuthenticationRoutes, MainRoutes])
  return <>{routing}</>
}

export default Routes
