import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Badge, Box, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import React from 'react'
import { useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import { selectRedirectModal } from 'store/reducers/RedirectModalReducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      gap: '3rem',
      [theme.breakpoints.down('md')]: {
        gap: '1rem'
      }
    },
    dialogContent: {
      overflow: 'hidden'
    },
    badgeContainer: {
      cursor: 'pointer',
      width: '17ch',
      '& .MuiBadge-badge': {
        height: 'fit-content',
        aspectRatio: '1',
        borderRadius: '50%',
        backgroundColor: '#e3f2fd',
        fontWeight: 'bold',
        color: '#0b5559'
      },
      '& .MuiBadge-badge:empty': {
        display: 'none'
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiBadge-anchorOriginTopRightRectangle': {
          transform: 'scale(1) translate(26%, -26%)'
        },
        height: '55px',
        width: 'fill-available'
      }
    },
    rectangle: {
      width: 'inherit',
      aspectRatio: '1',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        gap: '5px'
      }
    }
  })
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const RedirectDialog = () => {
  const classes = useStyles()
  const { open, title, actions, closeCallback } = useSelector(
    selectRedirectModal
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeCallback}
        TransitionComponent={Transition}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.container}>
            {actions?.map(action => (
              <Badge
                onClick={action.callback}
                key={action.label}
                className={classes.badgeContainer}
              >
                <div className={classes.rectangle}>
                  <Icon style={{ fontSize: 35 }}>{action.icon}</Icon>
                  <Typography align="center" style={{ fontWeight: 'bold' }}>
                    {action.label}
                  </Typography>
                </div>
              </Badge>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RedirectDialog
