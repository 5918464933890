import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { openModal } from 'store/reducers/ModalReducer'
import { isAxiosError } from 'utils/errors'
import axiosInstance, { statuses } from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import {
  IGenericReservationParams,
  IReservationsError,
  IThunkWithModal
} from '../../models/Reservation.model'
import { AppDispatch } from '../configureStore'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from '../reducers/ToastReducer'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'

export const createGenericReservation = createAsyncThunk<
  any,
  IThunkWithModal<IGenericReservationParams> | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    dispatch: AppDispatch
  }
>(
  'reservation/createGenericReservation',
  async ({ params, modal }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(show())

      const { data } = await axiosInstance.post<any>(
        RES_CONTROLLER.createGeneric,
        {
          ...params
        }
      )

      dispatch(hide())

      if (modal) {
        dispatch(openModal(modal))
      }

      dispatch(
        showToast({
          type: 'success',
          message: 'Prenotazione creata con successo'
        })
      )

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: createGenericReservation,
              params
            })
          )
        }

        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              'Errore durante la creazione della prenotazione'
          })
        )

        dispatch(hide())

        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
