import React, { useState } from 'react'
import { Container } from './Booking.Styled'
import MyBooks from './MyBooks'

export type IMode =
  | 'DAILY'
  | 'REL_MONTHLY'
  | 'ABS_MONTHLY'
  | 'WEEKLY'
  | 'GENERIC'
export type IRangeType = 'END_DATE' | 'NUMBERED'

function Booking() {
  const [filter] = useState<string>('NONE')

  return (
    <>
      <Container>
        <MyBooks filter={filter} />
      </Container>
    </>
  )
}

export default Booking

export const MemoBooking = React.memo(Booking)
