import moment from 'moment'

export const getdayMonth = function (data: string): string {
  let day = moment(data).format('D')
  let month = moment(data).format('MMMM')

  const monthUp = month.charAt(0).toUpperCase() + month.slice(1)

  return `${day} ${monthUp}`
}
