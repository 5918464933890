import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../configureStore'

interface UserIcon {
  nome: 'up' | 'down' | null
  cognome: 'up' | 'down' | null
  username: 'up' | 'down' | null
}
const initialState: UserIcon = {
  nome: null,
  cognome: null,
  username: null
}

export const iconSlice = createSlice({
  name: 'icon',
  initialState,
  reducers: {
    toogleIcon: (state, action: PayloadAction<UserIcon>) => {
      state.nome = action.payload.nome
      state.cognome = action.payload.cognome
      state.username = action.payload.username
    },
    resetIcon: state => {
      state.nome = null
      state.cognome = null
      state.username = null
    }
  }
})
export const selectIconState = (state: RootState) => state.userIcon
export const { toogleIcon, resetIcon } = iconSlice.actions

export default iconSlice.reducer
