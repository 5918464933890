import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        marginTop: '50px'
      },
      display: 'flex',
      justifyContent: 'center'
    }
  })
)

interface paginationProps {
  totalPages: number
  handlePagination: (val: number) => void
}

const PaginationTable: React.FC<paginationProps> = ({
  totalPages,
  handlePagination
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Pagination
        onChange={(event, val) => handlePagination(val)}
        size="medium"
        count={totalPages}
        showFirstButton
        showLastButton
      />
    </div>
  )
}
export default PaginationTable
