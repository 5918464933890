import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useKeycloak } from 'keycloak-react-web'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { setError } from 'store/reducers/ErrorActionReducer'
import { resetSignIn } from '.'
type Props = {
  children: ReactNode
}

const RefreshTokenWrapper = ({ children }: Props) => {
  const { error, lastAction, params } = useAppSelector(s => s.errorAction)
  const dispatch = useAppDispatch()
  const { initialized, keycloak } = useKeycloak()
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (error && initialized) {
        try {
          const isUpdated = await keycloak.updateToken(10)

          if (isUpdated) {
            setUpdated(true)
            return
          }
        } catch (error) {
          resetSignIn(true)
        }
      }
    })()
  }, [error, initialized, keycloak])

  useEffect(() => {

    if (updated) {
      localStorage.setItem('userJwt', keycloak.token)
      dispatch(setError(false))
      dispatch(lastAction(params))
      setUpdated(false)
    }
  }, [keycloak.token, lastAction, updated ])

  return <>{children}</>
}

export default RefreshTokenWrapper
