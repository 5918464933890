import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../configureStore'

interface Icon {
  sala: 'up' | 'down' | null
  posto: 'up' | 'down' | null
  persona: 'up' | 'down' | null
  stato: 'up' | 'down' | null
}
const initialState: Icon = {
  sala: null,
  posto: null,
  persona: null,
  stato: null
}

export const iconSlice = createSlice({
  name: 'icon',
  initialState,
  reducers: {
    toogleIcon: (state, action: PayloadAction<Icon>) => {
      state.sala = action.payload.sala
      state.posto = action.payload.posto
      state.persona = action.payload.persona
      state.stato = action.payload.stato
    },
    resetIcon: state => {
      state.sala = null
      state.posto = null
      state.persona = null
      state.stato = null
    }
  }
})
export const selectIconState = (state: RootState) => state.icon
export const { toogleIcon, resetIcon } = iconSlice.actions

export default iconSlice.reducer
