export const SIGN_UP_PATH = 'sign-up'
export const RESERVATIONS_PATH = 'reservations'
export const GROUP_BOOKING_PATH = 'group-booking'
export const USER_PATH = 'users'
export const GROUP_PATH = 'groups'
export const APPROVAL_BOOKING_PATH = 'approval-booking'
export const ADD_RESERVATION_PATH = 'add-reservation'
export const RESET_PASSWORD = 'reset-password'
export const DETAILS_TABLE_RESERVATION = 'details-table'
export const RECOVER_PASSWORD = 'recover-password'
