import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PaginationFooter from './PaginationFooter'
import UserTableHeader from './UserTableHeader'
import { Authority, ISimpleUser } from '../../../../models/Auth.model'
import TableRowUsers from './TableRowUsers'
import { useDispatch } from 'react-redux'
import { setUsers } from 'store/reducers/userControllerReducer'
import useTable from './useTable'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    tableLayout: 'fixed'
  },
  tableContainer: {
    boxShadow: 'none',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}))

interface tableProps {
  users: ISimpleUser[]
  authorities: Authority[]
  rowsPerPage: number
}

const UsersTable: React.FC<tableProps> = ({
  users,
  authorities,
  rowsPerPage
}) => {
  const classes = useStyles()
  const header: string[] = ['nome', 'cognome', 'username', 'azione']
  const dispatch = useDispatch()
  //function to order user by attribute
  const orderBy = (attribute: string) => {
    return (a: ISimpleUser, b: ISimpleUser) => {
      switch (attribute) {
        case 'nome':
          return a.firstname < b.firstname
            ? -1
            : a.firstname > b.firstname
            ? 1
            : 0
        case 'cognome':
          return a.lastname < b.lastname ? -1 : a.lastname > b.lastname ? 1 : 0
        case 'username':
          return a.username < b.username ? -1 : a.username > b.username ? 1 : 0
      }
    }
  }

  const sort = (attribute: string, type: string) => {
    if (type === 'up') {
      var arrayForSort = [...users]
      arrayForSort.sort(orderBy(attribute))
      users = arrayForSort
      dispatch(setUsers(users))
    }
    if (type === 'down') {
      arrayForSort = [...users]
      arrayForSort.sort(orderBy(attribute)).reverse()
      users = arrayForSort
      dispatch(setUsers(users))
    }
  }

  const [page, setPage] = useState(1)
  const { slice, range } = useTable(users ? users : [], page, rowsPerPage)

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map(cell => (
              <UserTableHeader key={cell} iconLabel={cell} sort={sort} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {slice &&
            slice.map(row => (
              <TableRowUsers
                key={row.id}
                rowUser={row}
                authorities={authorities}
              />
            ))}
        </TableBody>
      </Table>
      <PaginationFooter
        range={range}
        page={page}
        setPage={setPage}
        slice={slice}
      />
    </TableContainer>
  )
}

export default React.memo(UsersTable)
