import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { IOption } from 'models/Auth.model'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { openFormModal } from 'store/reducers/ConfirmSeatModalReducer'
import { selectAvailableSeats } from 'store/reducers/ReservationsReducer'
import { updateReservationStatus } from 'store/thunk/updateStatus'
import { getDayNameByDate } from 'utils/dateformat'
import { selectUser } from '../../../store/reducers/AuthReducer'
import { Hr } from '../common'
import ChipMonth from './ChipMonth'
import InfoDay from './InfoDay'
import Item from './Item'

const ACTIONS: IOption[] = [
  {
    name: 'Cancella',
    action: (id: number, dispatch) => {
      dispatch(updateReservationStatus({ id, action: 'CANCELLED' }))
    },
    roles: [
      'ROLE_USER',
      'ROLE_ADMIN',
      'ROLE_GROUP_ADMIN',
      'ROLE_USER_SUPERVISOR',
      'ROLE_GROUP_SUPERVISOR'
    ]
  },
  {
    name: 'Accetta',
    action: (id: number, dispatch) => {
      dispatch(openFormModal({ reservationId: id, title: '' }))
    },
    isVisible: (status: string) => status !== 'ACCEPTED',
    roles: ['ROLE_ADMIN', 'ROLE_GROUP_SUPERVISOR', 'ROLE_USER_SUPERVISOR']
  },
  {
    name: 'Rigetta',
    action: (id: number, dispatch) => {
      dispatch(updateReservationStatus({ id, action: 'REJECTED' }))
    },
    roles: ['ROLE_ADMIN', 'ROLE_GROUP_SUPERVISOR', 'ROLE_USER_SUPERVISOR']
  }
]

const useStyles = makeStyles((theme: Theme) => ({
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& :last-child': {
      marginLeft: 'auto'
    }
  },
  reservationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '225.5px 1fr',
      gridTemplateRows: '1fr',
      gap: '0px 0px',
      gridTemplateAreas: `"infoDay reservation"`,
      marginBottom: '25px'
    }
  },
  reservationCard: {
    gridArea: 'reservation'
  },
  reservationInfoDay: {
    gridArea: 'infoDay'
  }
}))

const ItemsGroup = ({ group, lastMonth, filter, showReservationName }) => {
  const classes = useStyles()
  const [options, setOptions] = useState<IOption[]>([])
  const { authority_names } = useSelector(selectUser)
  const availableSeats = useSelector(selectAvailableSeats)
  const [bookingLabelForms] = useState(
    new Map([
      ['singular', 'prenotazione caricata'],
      ['plural', 'prenotazioni caricate']
    ])
  )

  useEffect(() => {
    let obj: IOption[] = []
    ACTIONS?.forEach(el => {
      let canAccess = false
      el.roles.forEach(role => {
        if (authority_names.includes(role)) canAccess = true
      })

      if (canAccess) {
        obj.push(el)
      }
    })
    setOptions(obj)
  }, [authority_names])

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <div className={classes.flexDiv}>
          <ChipMonth month={group.month} />
          <Typography
            variant="body2"
            style={{
              float: 'right',
              color: 'teal'
            }}
          >
            {`${group.totalReservations} ${bookingLabelForms.get(
              group.totalReservations > 1 ? 'plural' : 'singular'
            )}`}
          </Typography>
        </div>
      </div>
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ paddingBottom: 15, justifyContent: 'space-between' }}
      >
        {group.days.map((reservationsPerDay, index) => {
          return reservationsPerDay.reservations.map((value, indexDay) => {
            if (
              filter === 'NONE' ||
              (filter === value.reservationStatus.status && !!availableSeats)
            ) {
              return (
                <Box key={indexDay} className={classes.reservationContainer}>
                  {indexDay === 0 && (
                    <Box flexGrow={1} style={{ width: '100%' }}>
                      <InfoDay
                        // TODO Error detected by the pipelines
                        // @ts-ignore
                        dayReserv={moment(value.dateEnd).format('YYYY-MM-DD')}
                        badgeContent={
                          (Object.entries(availableSeats ?? {})
                            .map(([k, v]) => [
                              (moment(k).format('DD-MM-YYYY'), v)
                            ])
                            .find(
                              ([k, _v]) =>
                                k.toString() ===
                                moment(value.dateStart).format('DD-MM-YYYY')
                            ) ?? '')[1]?.toString() ?? ''
                        }
                        dayOfWeek={getDayNameByDate(
                          value.dateStart,
                          'lowercase'
                        )}
                        dayNumber={new Date(value.dateStart).getDate()}
                      />
                    </Box>
                  )}
                  <Box flexGrow={6} className={classes.reservationCard}>
                    <Item
                      key={value.id}
                      showReservationName={showReservationName}
                      month={group.month}
                      actions={options}
                      {...value}
                      {...(index + 1 === group.totalReservations && lastMonth)}
                    />
                  </Box>
                </Box>
              )
            }
            return null
          })
        })}
      </Grid>
      <Hr />
    </>
  )
}
export default ItemsGroup
