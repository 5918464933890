import {
  AnyAction,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit'
import {
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate,
  IReservationsError,
  ISeat
} from '../../models/Reservation.model'
import { RootState } from '../configureStore'
import { countSeats } from '../thunk/countSeatsOnDates'
import { createGroupRecursiveReservation } from '../thunk/createGroupRecursiveReservation'
import { createGroupGenericReservation } from '../thunk/createGroupReservationGeneric'
import { createRecursiveReservation } from '../thunk/createRecursiveReservation'
import { createGenericReservation } from '../thunk/createReservationGeneric'
import { createSpecificReservation } from '../thunk/createSpecificReservations'
import { findAvailableSeats } from '../thunk/findAvailableSeats'
import { getReservationDetail } from '../thunk/getReservationDetail'
import { getGroupReservations } from '../thunk/groupReservations'
import { getGroupReservationsbyDay } from '../thunk/getReservationsDay'
import { myReservations } from '../thunk/myReservations'
import { myReservationsCalendar } from '../thunk/myReservationsCalendar'
import { toApproveReservations } from '../thunk/toApproveReservations'
import { updateReservation } from '../thunk/updateReservation'
import { updateReservationStatus } from '../thunk/updateStatus'
import { countTotalSeats } from '../thunk/countTotalSeat'
import { approveReservation } from 'store/thunk/approveReservation'

const hasPrefix = (action: AnyAction, prefix: string) =>
  action.type.startsWith(prefix)
const isPending = (action: AnyAction) => action.type.endsWith('/pending')
const isRejected = (action: AnyAction) => action.type.endsWith('/rejected')
//const isFulfilled = (action: AnyAction) => action.type.endsWith('/fulfilled')

const isPendingAction = (prefix: string) => (
  action: AnyAction
): action is AnyAction => {
  return hasPrefix(action, prefix) && isPending(action)
}

const isRejectedAction = (prefix: string) => (
  action: AnyAction
): action is AnyAction => {
  return hasPrefix(action, prefix) && isRejected(action)
}

export interface IResState {
  loading: boolean
  myreservations:
    | IMyReservationsResponse<IReservationContentWithDate>
    | undefined
  toapprovereservations:
    | IMyReservationsResponse<IReservationContentWithDate>
    | undefined
  groupreservations:
    | IMyReservationsResponse<IReservationContentWithDate>
    | undefined
  detailreservation: IReservationContent | undefined
  error: IReservationsError | undefined
  numberOfSeats: { [date: string]: number } | undefined
  seats: ISeat[] | undefined
  totalSeat: number | undefined
}

export const initialState: IResState = {
  loading: false,
  error: undefined,
  myreservations: undefined,
  toapprovereservations: undefined,
  groupreservations: undefined,
  detailreservation: undefined,
  seats: undefined,
  numberOfSeats: undefined,
  totalSeat: undefined
}

export const reservationsSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    resetMyReservations: state => {
      state.myreservations = undefined
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        myReservations.fulfilled,
        (
          state,
          action: PayloadAction<
            IMyReservationsResponse<IReservationContentWithDate>
          >
        ) => {
          state.loading = false
          state.myreservations = action.payload
        }
      )
      .addCase(
        myReservationsCalendar.fulfilled,
        (
          state,
          action: PayloadAction<
            IMyReservationsResponse<IReservationContentWithDate>
          >
        ) => {
          state.loading = false
          state.myreservations = action.payload
        }
      )
      .addCase(
        toApproveReservations.fulfilled,
        (
          state,
          action: PayloadAction<
            IMyReservationsResponse<IReservationContentWithDate>
          >
        ) => {
          state.loading = false
          state.toapprovereservations = action.payload
        }
      )
      .addCase(countSeats.fulfilled, (state, action) => {
        state.loading = false
        state.numberOfSeats = action.payload
      })
      .addCase(countTotalSeats.fulfilled, (state, action) => {
        state.loading = false
        state.totalSeat = action.payload
      })
      .addCase(createGenericReservation.fulfilled, state => {
        state.loading = false
      })
      .addCase(createGroupGenericReservation.fulfilled, state => {
        state.loading = false
      })
      .addCase(createSpecificReservation.fulfilled, state => {
        state.loading = false
      })
      .addCase(getGroupReservations.fulfilled, (state, action) => {
        state.groupreservations = action.payload
        state.loading = false
      })
      .addCase(getGroupReservationsbyDay.fulfilled, (state, action) => {
        state.groupreservations = action.payload
        state.loading = false
      })
      .addCase(getReservationDetail.fulfilled, (state, action) => {
        state.detailreservation = action.payload
        state.loading = false
      })
      .addCase(findAvailableSeats.fulfilled, (state, action) => {
        state.loading = false
        state.seats = action.payload
      })
      .addCase(createRecursiveReservation.fulfilled, state => {
        state.loading = false
      })
      .addCase(createGroupRecursiveReservation.fulfilled, state => {
        state.loading = false
      })
      .addMatcher(isPendingAction('reservation/'), state => {
        state.loading = true
      })
      .addMatcher(isRejectedAction('reservation/'), (state, action) => {
        state.loading = false
        state.error = action.payload?.data
      })
      .addMatcher(
        isAnyOf(
          approveReservation.fulfilled,
          updateReservation.fulfilled,
          updateReservationStatus.fulfilled
        ),
        (state, action: PayloadAction<IReservationContent>) => {
          state.loading = false
          ;[
            'myreservations',
            'toapprovereservations',
            'groupreservations'
          ].forEach(el => {
            if (state[el]) {
              const foundIndex = state[el].content.findIndex(
                ({ id }) => id === action.payload.id
              )

              if (foundIndex > -1) {
                const found = state[el].content.find(
                  ({ id }) => id === action.payload.id
                )
                state[el].content.splice(foundIndex, 1, {
                  ...found,
                  ...action.payload
                })
              }
            }
          })
        }
      )
  }
})
export const { resetMyReservations } = reservationsSlice.actions
export const selectReservations = (state: RootState): IResState =>
  state.reservations

export const selectMyReservations = (
  state: RootState
): IMyReservationsResponse<IReservationContentWithDate> | undefined =>
  state.reservations.myreservations

export const selectAvailableSeats = (
  state: RootState
): IResState['numberOfSeats'] => state.reservations.numberOfSeats

export default reservationsSlice.reducer
