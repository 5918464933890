import {
  Avatar,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Theme,
  Typography
} from '@material-ui/core'
// assets
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
// material-ui
import { makeStyles } from '@material-ui/styles'
import { useKeycloak } from 'keycloak-react-web'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout, selectDetails, selectUser } from 'store/reducers/AuthReducer'
import { resetMyReservations } from 'store/reducers/ReservationsReducer'
// project imports

// style const
const useStyles = makeStyles((theme: Theme) => ({
  navContainer: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    },
    paddingBottom: '0px'
  },
  headerAvatar: {
    cursor: 'pointer',
    width: '34px',
    height: '34px',
    fontSize: '1.2rem',
    margin: '8px 0 8px 8px !important'
  },
  profileChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    transition: 'all .2s ease-in-out',
    backgroundColor: '#BDE7E5',
    border: '0px',
    '&[aria-controls="menu-list-grow"], &:hover': {
      background: `${theme.palette.secondary.main}!important`,
      color: theme.palette.primary.light,
      '& svg': {
        color: 'white'
      }
    }
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px'
  },
  listItem: {
    '-webkit-tap-highlight-color': 'transparent',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '& div': {
        color: 'teal'
      }
    }
  },
  cardContent: {
    padding: '16px !important'
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    marginTop: '16px'
  },
  searchControl: {
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px',
    marginTop: '16px'
  },
  startAdornment: {
    fontSize: '1rem',
    color: theme.palette.grey[500]
  },
  flex: {
    display: 'flex',
    gap: '4px'
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400
  },
  role: {
    fontSize: '0.75rem',
    color: 'rgb(158, 158, 158)'
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'hidden'
  },
  badgeWarning: {
    backgroundColor: theme.palette.warning.dark,
    color: '#fff'
  },
  profilePopupContainer: {
    borderRadius: '12px',
    '& div.MuiPaper-root': {
      borderRadius: '12px'
    }
  }
}))

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = () => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const userDetail = useSelector(selectDetails)
  const userInfo = useSelector(selectUser)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { keycloak } = useKeycloak()

  const handleLogout = async () => {
    keycloak.logout()
  }

  const [roleNames] = React.useState(
    new Map([
      ['ROLE_USER', ''],
      ['ROLE_ADMIN', 'Admin'],
      ['ROLE_GROUP_ADMIN', 'Admin di gruppo'],
      ['ROLE_USER_SUPERVISOR', 'Supervisore'],
      ['ROLE_GROUP_SUPERVISOR', 'Supervisore di gruppo']
    ])
  )
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = event => {
    if (anchorRef.current?.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    if (!keycloak.authenticated) {
      dispatch(logout())
      dispatch(resetMyReservations())
      localStorage.removeItem('userJwt')
      navigate(`/`, { replace: true })
    }
    prevOpen.current = open
  }, [dispatch, keycloak.authenticated, navigate, open])
  return (
    <>
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        icon={
          <Avatar
            src={`https://eu.ui-avatars.com/api/?name=${
              userDetail?.firstname ?? ''
            }+${userDetail?.lastname ?? ''}&color=ffffff&background=41b6ae`}
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <KeyboardArrowDownRoundedIcon color="secondary" fontSize="large" />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {_ => (
          <Paper className={classes.profilePopupContainer}>
            <ClickAwayListener onClickAway={handleClose}>
              <Card elevation={16}>
                <CardContent className={classes.cardContent}>
                  <Grid container direction="column" spacing={0}>
                    <Grid item className={classes.flex}>
                      <Typography
                        component="span"
                        variant="h6"
                        className={classes.name}
                      >
                        {userDetail.firstname}
                        {' '}
                        {userDetail.lastname}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="subtitle2" className={classes.role}>
                        {roleNames.has(
                          userInfo.authority_names[
                            userInfo.authority_names.length - 1
                          ]
                        ) &&
                          roleNames.get(
                            userInfo.authority_names[
                              userInfo.authority_names.length - 1
                            ]
                          )}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider />

                  <List component="nav" className={classes.navContainer}>
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <ExitToAppRoundedIcon fontSize="default" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Logout</Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  )
}

export default ProfileSection
