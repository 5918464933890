import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '95%',
    height: 'auto',
    background: 'white',
    paddingLeft: '25px',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderRadius: '25px',
    margin: 'auto',
    marginTop: '25px',
    marginBottom: '50px',
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  containerDesc: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const legenda = [
  {
    type: 'Singola',
    text: 'prenotazione singola, per una sola persona'
  },
  {
    type: 'Ricorsiva',
    text: 'prenotazione che si ripete più volte'
  },
  {
    type: 'Per me',
    text: `prenotazione per l'utente corrente`
  },
  {
    type: 'Per altri',
    text: 'prenotazione per altri utenti'
  },
  {
    type: 'Freq. ripetizione',
    text: 'ogni quanti giorni si ripeterà la prenotazione'
  },
  {
    type: 'N. ripetizioni',
    text: 'quante volte verrà ripetuta la prenotazione'
  },
  {
    type: 'Scegli il piano e posti',
    text: 'piano e numero della postazione'
  }
]

const LegendaPrenotazioni = () => {
  const classes = useStyles()
  return (
    <section className={classes.container}>
      <Typography variant="h5" color="initial">
        Legenda
      </Typography>
      <div className={classes.containerDesc}>
        {legenda.map(l => {
          return (
            <section key={l.type} style={{ display: 'flex', gap: '5px' }}>
              <Typography
                variant="subtitle2"
                color="initial"
                style={{ fontWeight: 200 }}
              >
                <strong style={{ color: ' #37a5d7' }}>{l.type}:</strong>
                {l.text}
              </Typography>
            </section>
          )
        })}
      </div>

      <div className={classes.containerDesc}></div>
    </section>
  )
}

export default LegendaPrenotazioni
