export const getUrlParams = (params): string => {
  const parsedparams = Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => `${key}=${v}`).join('&')
      }
      return `${key}=${value}`
    })
    .join('&')

  return encodeURI(`?${parsedparams}`)
}
