import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/configureStore'

interface InitialStateProps {
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
  show: boolean
  time?: null | 3000
}

const initialState: InitialStateProps = {
  message: '',
  type: 'success',
  show: false,
  time: 3000
}

interface PayloadProps {
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
  time?: null | 3000
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<PayloadProps>) => {
      const { type, message, time } = action.payload
      state.message = message
      state.type = type
      state.show = true
      state.time = time === null ? time : 3000
    },
    hideToast: state => {
      state.show = false
      state.message = ''
    }
  }
})
export const selectToastState = (state: RootState) => state.toast
export const { showToast, hideToast } = toastSlice.actions

export default toastSlice.reducer
