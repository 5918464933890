import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded'
import EventSeatIcon from '@material-ui/icons/EventSeat'
import PieChartIcon from '@material-ui/icons/PieChart'
import Tooltip from '@material-ui/core/Tooltip'
import { infoSeatReserv } from '../../../utils/getSeatsReservation'

const BannerInfoTable = ({
  dayMonth,
  seats
}: {
  dayMonth: string
  seats: infoSeatReserv
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      // marginTop: '10px',
      display: 'flex',
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      padding: '10px 10px 10px 10px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    day: {
      width: '230px',
      justifySelf: 'flex-start',
      fontWeight: 900
    },
    containerIcon: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      gap: '10px'
    },
    icon: {
      height: '60px',
      width: '60px',
      padding: '8px 10px',
      backgroundColor: '#bfe7e4',
      color: '#0b5559',
      borderRadius: '50%',
      [theme.breakpoints.up('md')]: {
        marginRight: '10px '
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        margin: 'auto'
      }
    },
    divIcon: {
      display: 'flex',
      gap: '5px',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },

    textIcon: {
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column'
    },
    titleicon: {
      color: '#717171',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        display: 'block'
      }
    },
    seaticon: {
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'start'
      }
    }
  }))
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography
        variant="h5"
        color="initial"
        align="center"
        noWrap
        className={classes.day}
      >
        {dayMonth}
      </Typography>

      <div className={classes.containerIcon}>
        <div className={classes.divIcon}>
          <Tooltip placement="top" title={`Posti Prenotati`}>
            <LibraryBooksRoundedIcon className={classes.icon} />
          </Tooltip>

          <div className={classes.textIcon}>
            <Typography className={classes.titleicon} variant="h5">
              Posti Prenotati
            </Typography>

            <Typography
              className={classes.seaticon}
              variant="h6"
              color="initial"
            >
              {seats.booked}
            </Typography>
          </div>
        </div>

        <div className={classes.divIcon}>
          <Tooltip placement="top" title={`Posti Disponibili`}>
            <EventSeatIcon className={classes.icon} />
          </Tooltip>
          <div className={classes.textIcon}>
            <Typography
              className={classes.titleicon}
              variant="h5"
              color="initial"
            >
              Posti Disponibili
            </Typography>

            <Typography
              className={classes.seaticon}
              variant="h6"
              color="initial"
            >
              {seats.available}
            </Typography>
          </div>
        </div>

        <div className={classes.divIcon}>
          <Tooltip placement="top" title={` Posti Totali`}>
            <PieChartIcon className={classes.icon} />
          </Tooltip>
          <div className={classes.textIcon}>
            <Typography
              className={classes.titleicon}
              variant="h5"
              color="initial"
            >
              Posti Totali
            </Typography>

            <Typography
              className={classes.seaticon}
              variant="h6"
              color="initial"
            >
              {seats.total}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerInfoTable
