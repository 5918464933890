import { Avatar, ButtonBase, Theme, Typography } from '@material-ui/core'
// assets
import MenuIcon from '@material-ui/icons/Menu'
// material-ui
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import NewReservation from './NewReservation'
import ProfileSection from './ProfileSection'
import {Logo} from "../../../pages/LoginPage/LoginPage.styled";
import {messages} from "../../../components/Copyright/messages";
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  headerAvatar: {
    cursor: 'pointer',
    borderRadius: '8px',
    width: '34px',
    height: '34px',
    fontSize: '1.2rem',
    transition: 'all .2s ease-in-out',
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white'
    }
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}))

const StyledTypography = styled(Typography)`
  margin-left: 8px;
`

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header: React.VFC<{ handleLeftDrawerToggle: () => void }> = ({
  handleLeftDrawerToggle
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      {/* toggle button & logo */}
      <div className={classes.boxContainer}>
        <ButtonBase>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon fontSize="default" />
          </Avatar>
        </ButtonBase>

        {/* logo */}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Logo src="logo-tak.svg" />
        </Box>
      </div>

      {/* title */}
      <StyledTypography variant="body1" color="textSecondary" align="center">
        {t(...messages.description())}
      </StyledTypography>

      <div className={classes.grow} />

      {/* newReservation & profile */}
      <NewReservation />
      <ProfileSection />
    </>
  )
}

export default Header
