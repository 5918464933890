import { IOption } from '../models/Auth.model'
import { updateReservationStatus } from '../store/thunk/updateStatus'
import { openFormModal } from '../store/reducers/ConfirmSeatModalReducer'

export const ACTIONS: IOption[] = [
  {
    name: 'Cancella',
    action: (id: number, dispatch) => {
      dispatch(updateReservationStatus({ id, action: 'CANCELLED' }))
    },
    roles: [
      'ROLE_USER',
      'ROLE_ADMIN',
      'ROLE_GROUP_ADMIN',
      'ROLE_USER_SUPERVISOR',
      'ROLE_GROUP_SUPERVISOR'
    ]
  },
  {
    name: 'Accetta',
    action: (id: number, dispatch) => {
      dispatch(openFormModal({ reservationId: id, title: '' }))
    },
    roles: ['ROLE_ADMIN', 'ROLE_GROUP_SUPERVISOR', 'ROLE_USER_SUPERVISOR']
  },
  {
    name: 'Rigetta',
    action: (id: number, dispatch) => {
      dispatch(updateReservationStatus({ id, action: 'REJECTED' }))
    },
    roles: ['ROLE_ADMIN', 'ROLE_GROUP_SUPERVISOR', 'ROLE_USER_SUPERVISOR']
  }
]
