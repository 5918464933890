import React from 'react'
import moment from 'moment'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getGroupReservationsbyDay } from '../../../store/thunk/getReservationsDay'
import { useParams } from 'react-router'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'

import {
  selectIconState,
  toogleIcon
} from '../../../store/reducers/iconReducer'

const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    fontSize: '1.5rem',
    color: '#0b5559',
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '5px'
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
}))

const sortype = {
  salaAsc: 'seat.room.name,asc',
  salaDsc: 'seat.room.name,desc',
  postoAsc: 'seat,asc',
  postoDsc: 'seat,desc',
  personaAsc: 'user.firstname,asc',
  personaDsc: 'user.firstname,desc',
  statoAsc: 'status.status,asc',
  statoDsc: 'status.status,desc'
}

interface cellheaderProps {
  iconLabel: string
  paginationNumber: number
}

const TableCellHeader: React.FC<cellheaderProps> = ({
  iconLabel,
  paginationNumber
}) => {
  const { pageSize } = ENVIRONMENT_VARIABLES
  let { day } = useParams()
  const icons = useSelector(selectIconState)
  const dispatch = useDispatch()

  const iconstate = {
    sala: null,
    posto: null,
    persona: null,
    stato: null
  }

  const chanceIcon = icon => {
    if (icons[icon] === null) iconstate[icon] = 'up'
    if (icons[icon] === 'up') iconstate[icon] = 'down'
    if (icons[icon] === 'down') iconstate[icon] = 'up'
    dispatch(toogleIcon(iconstate))

    dispatch(
      getGroupReservationsbyDay({
        fromDate: moment(day).startOf('day').toISOString(),
        toDate: moment(day).endOf('day').toISOString(),
        page: paginationNumber,
        size: pageSize,
        sort: [
          'dateStart',
          `${
            iconstate[icon] === 'down'
              ? sortype[`${icon}Dsc`]
              : sortype[`${icon}Asc`]
          }`,
          'id'
        ],
        status: ['ACCEPTED', 'REJECTED', 'PENDING']
      })
    )
  }

  const classes = useStyles()

  return (
    <TableCell
      className={classes.tableHeaderCell}
      onClick={() => {
        chanceIcon(iconLabel)
      }}
    >
      <div className={classes.label}>
        <Typography variant="h6" color="initial">
          {capitalize(iconLabel)}
        </Typography>

        {icons[iconLabel] === null && <FormatLineSpacingIcon />}
        {icons[iconLabel] === 'up' && <ArrowUpwardIcon />}
        {icons[iconLabel] === 'down' && <ArrowDownwardIcon />}
      </div>
    </TableCell>
  )
}

export default TableCellHeader
