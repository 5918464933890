import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import { AppDispatch, RootState } from 'store/configureStore'
import { hide, show } from 'store/reducers/LoaderReducer'
import { isAxiosError } from 'utils/errors'
import { getUrlParams } from 'utils/urlrelated'
import axiosInstance, { statuses } from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import {
  IMyReservationParams,
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate,
  IReservationsError
} from '../../models/Reservation.model'
import { countSeats } from './countSeatsOnDates'
import { showToast } from 'store/reducers/ToastReducer'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'
export interface IGroupReservationsParams extends IMyReservationParams {
  username?: string
  status?: Array<string>
  pageSize?: number
}

export const getGroupReservations = createAsyncThunk<
  IMyReservationsResponse<IReservationContentWithDate>,
  IGroupReservationsParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    state: RootState
    dispatch: AppDispatch
  }
>(
  'reservation/groupReservations',
  async (
    params: IGroupReservationsParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      let path = getUrlParams(params)

      dispatch(show())

      const { data } = await axiosInstance.get<
        IMyReservationsResponse<IReservationContent>
      >(RES_CONTROLLER.byRole + path)

      dispatch(
        countSeats({
          fromDate: data.first
            ? new Date(data.content[0].dateStart).toISOString()
            : new Date(
                getState().reservations?.groupreservations?.content[0].dateStart
              ).toISOString(),
          toDate: moment(data.content[data.content.length - 1].dateStart)
            .add(1, 'days')
            .toISOString()
        })
      )

      dispatch(hide())
      if (params.page > 0) {
        if (data.first) {
          return {
            ...data
          }
        }
        return {
          ...data,
          content: [
            ...(getState().reservations?.groupreservations?.content ?? []),
            ...data.content
          ]
        }
      }

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: getGroupReservations,
              params
            })
          )
        }
        const error = err as AxiosError<IReservationsError>
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
