import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 1px;
  }
`

interface IFlexDiv {
  flexDirection: 'column' | 'row'
  lastChild?: boolean
  borderColor?: 'primary' | 'secondary'
}

export const FlexDiv = styled.div<IFlexDiv>`
  display: flex;
  flex-direction: ${p => p.flexDirection};
  border: solid
    ${p =>
      p.borderColor === 'primary'
        ? p.theme.palette.primary.main
        : p.borderColor === 'secondary'
        ? p.theme.palette.secondary.main
        : 'none'};

  & :last-child {
    margin-left: ${p => (p.lastChild ? 'auto' : 'none')};
  }
`

//status: 'NEW' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'PENDING';
export const colorByStatus = {
  NEW: '#5bc0de',
  ACCEPTED: '#5cb85c',
  REJECTED: '#d9534f',
  CANCELLED: '#c5d8e1',
  PENDING: '#fecf6d'
}
export const BaseInfoGrid = styled(Grid)`
  justify-content: center;
`
