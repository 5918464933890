import { Badge, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import {
  DETAILS_TABLE_RESERVATION,
  RESERVATIONS_PATH,
  APPROVAL_BOOKING_PATH
} from '../../routes/constants'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '10px 11px',
    width: '95px',
    borderRadius: '6px',
    userSelect: 'none',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    }
  },
  rectangle: {
    width: 'inherit',
    height: '95px',
    aspectRatio: '1',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      gap: '5px'
    }
  },
  availableSeatsBadge: {
    width: 'inherit',
    '& .MuiBadge-badge': {
      height: 'fit-content',
      aspectRatio: '1',
      borderRadius: '50%',
      backgroundColor: '#e3f2fd',
      fontWeight: 'bold',
      color: '#0b5559'
    },
    '& .MuiBadge-badge:empty': {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        transform: 'scale(1) translate(26%, -26%)'
      },
      width: 'fill-available'
    }
  }
}))
const InfoDay: React.VFC<{
  dayOfWeek: string
  dayNumber: string | number
  badgeContent: string | number
  dayReserv: string
}> = ({ dayOfWeek, dayNumber, badgeContent, dayReserv }) => {
  const { maximumAvailableSeats } = ENVIRONMENT_VARIABLES
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const handleClick = () => {
    if (
      location.pathname.localeCompare(
        `/${RESERVATIONS_PATH}/${APPROVAL_BOOKING_PATH}`
      )
    )
      return
    navigate(`${DETAILS_TABLE_RESERVATION}/${dayReserv}`, {
      replace: true
    })
  }

  return (
    <Paper
      className={classes.container}
      style={
        location.pathname.localeCompare(
          `/${RESERVATIONS_PATH}/${APPROVAL_BOOKING_PATH}`
        )
          ? { cursor: 'default' }
          : { cursor: 'pointer' }
      }
    >
      <Badge
        onClick={handleClick}
        color="secondary"
        badgeContent={
          badgeContent !== '' ? `${badgeContent}/${maximumAvailableSeats}` : ''
        }
        className={classes.availableSeatsBadge}
      >
        <div className={classes.rectangle}>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {dayNumber}
          </Typography>
          <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
            {dayOfWeek}
          </Typography>
        </div>
      </Badge>
    </Paper>
  )
}
export default InfoDay
