import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableRowReserv from './TableRowReserv'
import TableCellHeader from './TableCellHeader'
import PaginationTable from './PaginationTable'
import { IBigDataContent } from '../../../models/Reservation.model'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    tableLayout: 'fixed'
  },
  tableContainer: {
    boxShadow: 'none',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}))

interface tableProps {
  bigData: IBigDataContent
  handleStatusReserv: () => void
  totalPages: number
  paginationNumber: number
  handlePagination: (val: number) => void
}

const TableDetails: React.FC<tableProps> = ({
  bigData,
  handleStatusReserv,
  totalPages,
  paginationNumber,
  handlePagination
}) => {
  const classes = useStyles()
  const header: string[] = ['sala', 'posto', 'persona', 'stato']
  const { days } = { ...bigData }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map(cell => (
              <TableCellHeader
                key={cell}
                iconLabel={cell}
                paginationNumber={paginationNumber}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {days[0].reservations?.map(row => (
            <TableRowReserv
              key={row.id}
              rowReserv={row}
              handleStatusReserv={handleStatusReserv}
            />
          ))}
        </TableBody>
      </Table>
      <PaginationTable
        totalPages={totalPages}
        handlePagination={handlePagination}
      />
    </TableContainer>
  )
}

export default React.memo(TableDetails)
