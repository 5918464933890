import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { AuthError, IUser } from 'models/Auth.model'
import { isAxiosError } from 'utils/errors'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from '../reducers/ToastReducer'
import { getUserDetails } from './getUserDetails'
import { IReservationsError } from 'models/Reservation.model'

export const doLogin = createAsyncThunk<
  IUser,
  IUser,
  {
    rejectValue: AxiosResponse<AuthError>
  }
>('auth/login', async (user: IUser, { rejectWithValue, dispatch }) => {
  try {
    dispatch(show())

    const data = user

    localStorage.setItem('userJwt', data.access_token)

    if (data.access_token) {
      dispatch(getUserDetails())
    } else {
      dispatch(showToast({ type: 'error', message: 'Errore durante la login' }))
    }

    dispatch(hide())

    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError<IReservationsError>
      dispatch(hide())

      dispatch(
        showToast({
          type: 'error',
          // message: err.response?.data?.message || 'Nome utente o password errati'
          message: 'Nome utente o password errati'
        })
      )

      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})
