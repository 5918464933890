import styled from '@emotion/styled'
import {
  Badge,
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  withStyles
} from '@material-ui/core'
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone'
import Close from '@material-ui/icons/Close'
import React, { PropsWithChildren } from 'react'
import Calendar from 'react-calendar'
import { colorByStatus } from '../Booking/Booking.Styled'

export const BookingCalendar = styled(Calendar)`
  font-size: larger;
  border-color: #41b6ae;
  border-radius: 25px 2px 25px 35px;
  border-width: 2px;
  box-shadow: 5px 5px #41b6ae;
  background: whitesmoke;
  padding: 10px;
  margin: 0 auto;
  width: 100%;

  & .react-calendar__navigation button {
    border-radius: 25px;
    margin: 1px;
  }

  & .react-calendar__month-view__weekdays {
    display: flex;
    border-radius: 10px;
    margin: 0 0 5px;
    @media screen and (max-width: 900px) {
      font-size: 0.7em;
    }
  }

  & .react-calendar__month-view__days {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
  }

  & .react-calendar__tile.react-calendar__month-view__days__day {
    position: relative;
    font-size: x-large;

    &:hover {
      background: #41b6ae;
      color: white;
    }

    &--weekend {
      opacity: 0.8;
      color: #1655c8;

      &--active {
        color: whitesmoke;
      }
    }
  }

  & .react-calendar__tile--now {
    background: #37a5d7;

    &:enabled:hover,
    &:enabled:focus {
      background: #41b6ae;
    }
  }

  & .react-calendar__tile--active {
    background: #1655c8;
    color: white;
  }

  & .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }

  & .react-calendar__tile--day-full {
    opacity: 0.5;
  }

  & .pending {
    abbr {
      background: ${colorByStatus.PENDING};
    }
  }

  & .accepted {
    abbr {
      background: ${colorByStatus.ACCEPTED};
    }
  }

  & .cancelled {
    abbr {
      background: ${colorByStatus.CANCELLED};
    }
  }

  & .rejected {
    abbr {
      background: ${colorByStatus.REJECTED};
    }
  }

  & .new {
    abbr {
      background: ${colorByStatus.NEW};
    }
  }

  & .react-calendar__tile {
    border-radius: 5px;
    padding: 0;

    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px;
      padding: 0.75em 0.5em;
      border-radius: 32px;
      @media screen and (max-width: 900px) {
        word-break: keep-all;
      }
    }
  }
`

export const MyBadge = withStyles(theme => ({
  root: {
    wordBreak: 'keep-all',
    position: 'absolute',
    top: '10px',
    right: '13px'
  }
}))(Badge)

export const StyledCloseButton = withStyles(theme =>
  createStyles({
    root: {
      color: 'secondary',
      ariaLabel: 'close',
      cursor: 'pointer',
      float: 'right',
      width: '50px',
      height: '50px',
      margin: (props: StyledContainerProps) =>
        props.calendar ? '-10px -55px 0 0' : '-40px -40px 0 0'
    }
  })
)(Close)

export const StyledReservationButton = withStyles(theme => ({
  root: {
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
    borderRadius: '25px',
    boxShadow: `0 5px ${theme.palette.secondary.dark}`
  }
}))(Button)

export const BorderColorIcon = withStyles(theme => ({
  root: {
    cursor: 'pointer'
  }
}))(BorderColorTwoToneIcon)

export const StyledTextField = withStyles(theme => ({
  root: {
    '& label.MuiFormLabel-root': {
      color: theme.palette.primary.dark
    }
  }
}))(TextField)

type StyledContainerProps = { calendar?: boolean }

const useStyles = makeStyles<Theme, StyledContainerProps>(theme => ({
  root: {
    marginTop: (props: StyledContainerProps) => (props.calendar ? '0' : '50px'),
    maxWidth: (props: StyledContainerProps) =>
      props.calendar ? '700px' : '800px',
    backgroundColor: (props: StyledContainerProps) =>
      props.calendar ? 'unset' : 'white',
    borderRadius: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '50px',
      margin: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      margin: 'auto'
    }
  }
}))

export const StyledContainer: React.FC<PropsWithChildren<
  StyledContainerProps
>> = props => {
  const classes = useStyles(props)
  return <div className={classes.root}>{props.children} </div>
}
