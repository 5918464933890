import { useKeycloak } from 'keycloak-react-web'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginButton } from './LoginPage.styled'
import { messages } from './messages'
import { useDispatch } from 'react-redux'
import { doLogin } from 'store/thunk/login'
import { IUser } from 'models/Auth.model'

const LoginPage: React.VFC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (keycloak.authenticated) {
      const user: IUser = {
        user_id: null,
        access_token: keycloak.token,
        token_type: 'bearer',
        authority_names: ['ROLE_USER'],
        expires_date: new Date(keycloak.tokenParsed.exp * 1000).toISOString()
      }
      dispatch(doLogin(user))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keycloak.authenticated, keycloak.token])

  return (
    <LoginButton
      disabled={!keycloak}
      buttonText="ENTRA"
      onClick={() => keycloak.login()}
    >
      {t(...messages.form.btnConfirm())}
    </LoginButton>
  )
}

export default LoginPage
