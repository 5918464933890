import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { VFC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideToast, selectToastState } from 'store/reducers/ToastReducer'

const Toast: VFC = () => {
  const { message, show, type, time } = useSelector(selectToastState)
  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(hideToast())
  }

  return (
    <Snackbar open={show} autoHideDuration={time} onClose={handleClose}>
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  )
}

export default Toast
