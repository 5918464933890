import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'
export interface IRedirectAction {
  callback: () => void
  label: string
  icon: string
}
export interface IRedirectModal {
  title: string
  open: boolean
  selectedDate: string
  actions: IRedirectAction[]
  closeCallback?: () => void
}

const initialState: IRedirectModal = {
  title: '',
  open: false,
  selectedDate: '',
  actions: []
}

export const redirectModal = createSlice({
  name: 'redirectModal',
  initialState,
  reducers: {
    openRedirectModal(
      state,
      action: PayloadAction<Omit<IRedirectModal, 'open'>>
    ) {
      state.title = action.payload.title
      state.open = true
      state.actions = action.payload.actions
      state.selectedDate = action.payload.selectedDate
      state.closeCallback = action.payload.closeCallback
    },
    closeRedirectModal(state) {
      state.title = ''
      state.open = false
      state.actions = []
    }
  }
})

export const selectRedirectModal = (state: RootState): IRedirectModal =>
  state.redirectModal

export const { openRedirectModal, closeRedirectModal } = redirectModal.actions

export default redirectModal.reducer
