import { Drawer, Theme, useMediaQuery } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TodayIcon from '@material-ui/icons/Today'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import PersonIcon from '@material-ui/icons/Person'
// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectUser } from '../../../../store/reducers/AuthReducer'
import {
  RESERVATIONS_PATH,
  APPROVAL_BOOKING_PATH,
  GROUP_BOOKING_PATH,
  USER_PATH,
  ADD_RESERVATION_PATH,
  GROUP_PATH
} from '../../../routes/constants'
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: '260px',
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: '260px',
    paddingLeft: '16px',
    paddingRight: '16px',
    background: 'white',
    color: theme.palette.text.primary,
    borderRight: 'none',
    [theme.breakpoints.up('md')]: {
      top: '88px'
    }
  },
  ScrollHeight: {
    height: 'calc(100vh - 88px)',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)'
    }
  },
  boxContainer: {
    display: 'flex',
    padding: '16px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  listItem: {
    '-webkit-tap-highlight-color': 'transparent',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    padding: '10px 16px',
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      '& div': {
        color: 'teal'
      }
    }
  },
  listItemSelected: {
    backgroundColor: theme.palette.secondary.light,
    '& div': {
      color: 'teal'
    },
    '& .MuiListItemText-root>span.MuiTypography-root': {
      fontWeight: 'bold'
    }
  }
}))

// ===========================|| SIDEBAR DRAWER ||=========================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const classes = useStyles()
  const theme = useTheme() as Theme
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const location = useLocation()
  const navigate = useNavigate()
  const userInfo = useSelector(selectUser)
  const drawerList = [
    {
      path: `${ADD_RESERVATION_PATH}`,
      localpath: `/${RESERVATIONS_PATH}/${ADD_RESERVATION_PATH}`,
      label: 'Calendario',
      icon: <TodayIcon />,
      canView: [
        'ROLE_USER',
        'ROLE_ADMIN',
        'ROLE_GROUP_ADMIN',
        'ROLE_USER_SUPERVISOR',
        'ROLE_GROUP_SUPERVISOR'
      ]
    },
    {
      path: ``,
      localpath: `/${RESERVATIONS_PATH}`, //
      label: 'Mie prenotazioni',
      icon: <PersonIcon />,
      canView: [
        'ROLE_USER',
        'ROLE_ADMIN',
        'ROLE_GROUP_ADMIN',
        'ROLE_USER_SUPERVISOR',
        'ROLE_GROUP_SUPERVISOR'
      ]
    },
    {
      path: `${GROUP_BOOKING_PATH}`,
      localpath: `/${RESERVATIONS_PATH}/${GROUP_BOOKING_PATH}`,
      label: 'Di gruppo',
      icon: <GroupRoundedIcon />,
      canView: ['ROLE_ADMIN', 'ROLE_GROUP_ADMIN', 'ROLE_GROUP_SUPERVISOR']
    },
    {
      path: `${APPROVAL_BOOKING_PATH}`,
      localpath: `/${RESERVATIONS_PATH}/${APPROVAL_BOOKING_PATH}`,
      label: 'Da approvare',
      icon: <CheckRoundedIcon />,
      canView: ['ROLE_ADMIN', 'ROLE_USER_SUPERVISOR', 'ROLE_GROUP_SUPERVISOR']
    },
    {
      path: `${USER_PATH}`,
      localpath: `/${RESERVATIONS_PATH}/${USER_PATH}`,
      label: 'Utenti',
      icon: <GroupRoundedIcon />,
      canView: ['ROLE_ADMIN', 'ROLE_USER_SUPERVISOR']
    },
    {
      path: `${GROUP_PATH}`,
      localpath: `/${RESERVATIONS_PATH}/${GROUP_PATH}`,
      label: 'Gruppi',
      icon: <GroupRoundedIcon />,
      canView: ['ROLE_ADMIN', 'ROLE_GROUP_ADMIN', 'ROLE_GROUP_SUPERVISOR']
    }
  ]
  const handleDrawerItemClick = pathToRedirect => {
    location.pathname !== pathToRedirect && navigate(pathToRedirect)

    //close sidebar on mobile on path changes
    !matchUpMd && drawerToggle()
  }

  const drawer = (
    <List component="nav" aria-label="sidebar navigation list">
      {drawerList.map(
        drawerItem =>
          (userInfo?.authority_names ?? ['']).some(an =>
            drawerItem.canView.includes(an)
          ) && (
            <ListItem
              key={drawerItem.label}
              onClick={() => handleDrawerItemClick(drawerItem.path)}
              button
              className={clsx(classes.listItem, {
                [classes.listItemSelected]:
                  location.pathname === drawerItem.localpath
              })}
            >
              <ListItemIcon>{drawerItem.icon}</ListItemIcon>
              <ListItemText primary={drawerItem.label} />
            </ListItem>
          )
      )}
    </List>
  )

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </nav>
  )
}

export default Sidebar
