import * as React from 'react'
import { useEffect } from 'react'
import Routes from './routes'
import { value } from 'constants/conf'
import useScript from 'utils/useScript'

const r = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const App = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    Object.assign(root?.style, r)
  }, [])

  // start TAK floating menu
  useScript(value('REACT_APP_TAK_COMPANION_LINK'))

  return <Routes />
}

export default App
