import styled from 'styled-components'
import { css } from 'styled-components/macro'
import WhereToVoteToneIcon from '@material-ui/icons/WhereToVoteOutlined'
import BookToneIcon from '@material-ui/icons/BookOutlined'
import ShadowButton from '../../components/ShadowButton'
import Container from '@material-ui/core/Container'
import { Link } from '@material-ui/core'

/*
export const AvatarStyled = styled(Avatar)`
  margin: ${props => props.theme.spacing(1)};
  background-color: ${props => props.theme.palette.secondary.main};
`
*/

export const Form = styled.form`
  width: 100%; // Fix IE 11 issue.
  margin-top: ${p => p.theme.spacing(1)};
`

export const MainTitle = styled.h1`
  color: ${props => props.theme.palette.primary.main};
  font-size: 50px;
  font-family: 'Do Hyeon';
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.secondary};
  text-shadow: 6px 0 13px #fff;
  margin-bottom: 0;
`

export const Description = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  border-bottom: 6px solid ${props => props.theme.palette.secondary.main};
  text-align: center;
  display: block;
  margin: auto;
  transition: 0.2s;
  transition-timing-function: ease-in-out;

  :hover {
    transform: translateY(-5px);
  }
`

/*const boing = keyframes`
0% {
      opacity: 0;
      transform-origin: 50% 0%;
      transform: perspective(800px) rotateX(-90deg);
    }
    50% {
      opacity: 1;
      transform-origin: 50% 0%;
      transform: perspective(800px) rotateX(50deg);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 0%;
      transform: perspective(800px) rotateX(0deg);
    }

`;*/

export const IconContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`

const loginIcon = css`
  font-size: 40px;
  fill: ${props => props.theme.palette.secondary.color};
  transition: 0.2s;
  transition-timing-function: ease-in-out;
`

export const WhereToVoteIcon = styled(WhereToVoteToneIcon).attrs(() => ({
  color: 'primary'
}))`
  ${loginIcon};

  :hover {
    transform: rotate(-20deg);
  }
`

export const BookIcon = styled(BookToneIcon).attrs(() => ({
  color: 'primary'
}))`
  ${loginIcon};

  :hover {
    transform: rotate(20deg);
  }
`

export const LoginButton = styled(ShadowButton).attrs(
  ({ theme, disabled }) => ({
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    primary: true,
    size: 'large',
    disabled
  })
)`
  width: 100%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  box-shadow: none;

  :hover {
    box-shadow: none;
  }
`

export const StyledContainer = styled(Container).attrs(() => ({
  maxWidth: 'xs'
}))`
  padding: 0 20px;
`

export const Logo = styled.img`
  height: 60px;
  margin-left: 30px;
`

export const Register = styled.div`
  font-size: 16px;
`

export const Anchor = styled(Link)`
  cursor: pointer;
`
export const ResetPasswordLink = styled(Link)`
  font-family: 'Do Hyeon';
  text-align: right;
  cursor: pointer;
`
