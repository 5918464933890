import { Grid } from '@material-ui/core'
import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import { IBigDataContent } from '../../../models/Reservation.model'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import { getGroupReservations } from '../../../store/thunk/groupReservations'

import {
  filterReservations,
  groupReservationsByDay,
  normalizeReservation
} from '../../services/reservationService'
import Loader from '../Loader'
import NoData from '../NoData'
import ItemsGroup from './ItemsGroup'
import { useStyles } from './MyBooks'
interface GroupReservationsProps {
  filter: string
}

const GroupReservations: React.VFC<GroupReservationsProps> = ({ filter }) => {
  const { pageSize } = ENVIRONMENT_VARIABLES
  const { groupreservations, loading } = useSelector(selectReservations)
  const { pageable = { pageNumber: 0 }, last = false, empty = true } = {
    ...groupreservations
  }
  const { pageNumber = 0 } = { ...pageable }
  const [bigData, setBigData] = useState<IBigDataContent[]>([])

  const dispatch = useDispatch()

  const classes = useStyles()

  useEffect(() => {
    dispatch(
      getGroupReservations({
        fromDate: moment().startOf('day').toISOString(),
        page: 0,
        size: pageSize,
        sort: ['dateStart', 'id'],
        status: ['ACCEPTED', 'REJECTED', 'PENDING']
      })
    )
  }, [dispatch, pageSize])

  useEffect(() => {
    const normalizedReservations = normalizeReservation(groupreservations)
    const filteredReservations = filterReservations(
      'CANCELLED',
      normalizedReservations
    )
    const groupedByDay = groupReservationsByDay(filteredReservations)

    setBigData(groupedByDay)
  }, [groupreservations])

  const handleLoadMore = (inView: boolean) => {
    if (inView && !last && !loading) {
      dispatch(
        getGroupReservations({
          fromDate: moment().startOf('day').toISOString(),
          page: pageNumber + 1,
          size: pageSize,
          sort: ['dateStart', 'id'],
          status: ['ACCEPTED', 'REJECTED', 'PENDING']
        })
      )
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {bigData?.map((el, index) => (
          <ItemsGroup
            filter={filter}
            key={index}
            group={el}
            lastMonth={index + 1 === bigData.length}
            showReservationName
          />
        ))}
        {!bigData.length && !loading && (
          <NoData
            title="Tutti in SmartWorking!"
            description="nessuna prenotazione trovata"
          />
        )}
        {bigData.length > 0 && !empty && !last && (
          //TODO error detected in pipeline
          // @ts-ignore
          <InView as="div" onChange={inView => handleLoadMore(inView)}>
            <Loader visible={true} />
          </InView>
        )}
      </Grid>
    </Grid>
  )
}

export default GroupReservations
