import {
  Avatar,
  Chip,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import Zoom from '@material-ui/core/Zoom'
import { MoreVert } from '@material-ui/icons'
import { IOption } from 'models/Auth.model'
import React, { RefObject, useState, VFC } from 'react'
import { useDispatch } from 'react-redux'
import { IReservationContent } from '../../../models/Reservation.model'

type ItemProps = IReservationContent & {
  actions: IOption[]
  refObject: RefObject<any>
  position: string
  showReservationName: boolean
}

const ITEM_HEIGHT = 48
type PropStyles = {
  colorStatus: string
  backgroundStatus: string
}

const useStyles = makeStyles<Theme, PropStyles>((theme: Theme) => ({
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    }
  },
  contentRight: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    order: 2
  },
  reservationInfo: {
    order: 1
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 0.9,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
      justifyContent: 'center'
    }
  },
  moreBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& svg': {
      transform: 'rotate(90deg) scale(1.5)',
      [theme.breakpoints.down('md')]: {
        transform: 'rotate(90deg) scale(1.5) translateX(-7px)'
      }
    }
  },
  actionMenuItem: {
    '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      backgroundColor: 'inherit'
    }
  },
  avatar: {
    width: '67px',
    height: '67px',
    alignSelf: 'center'
  },
  userReservation: {
    transform: 'scale(.75)',
    backgroundColor: ({ colorStatus }) => colorStatus,
    color: ({ colorStatus }) => theme.palette.getContrastText(colorStatus)
  },
  chipStatus: {
    marginRight: '10px',
    fontWeight: 700,
    color: 'white',
    backgroundColor: ({ colorStatus }) => colorStatus
  },
  cardPaper: {
    display: 'flex',
    height: '95px',
    width: '100%',
    overflow: 'hidden',
    padding: '10px 15px',
    borderRadius: '15px',
    position: 'relative',
    gap: '20px',
    background: ({ backgroundStatus }) => backgroundStatus,
    color: ({ colorStatus }) => colorStatus,
    '&:hover': {
      backgroundImage: ({ colorStatus }) => `url(
        "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='${encodeURIComponent(
          colorStatus
        )}' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e"
      )`
    }
  }
}))

const colorStatusesMap = new Map([
  ['NEW', { color: '#FFF', background: '#d1cfcf' }],
  ['CANCELLED', { color: '#7f7f7f', background: '#cacaca' }],
  ['ACCEPTED', { color: '#0b5559', background: '#BDE7E5' }],
  ['REJECTED', { color: '#fd5050', background: '#FFEAE2' }],
  ['PENDING', { color: '#93771c', background: '#fffbee' }]
])

const Item: VFC<ItemProps> = ({
  actions,
  refObject,
  position,
  showReservationName,
  ...reservation
}) => {
  const dispatch = useDispatch()

  const classes = useStyles({
    colorStatus: colorStatusesMap.get(reservation.reservationStatus.status)
      .color,
    backgroundStatus: colorStatusesMap.get(reservation.reservationStatus.status)
      .background
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid
      key={reservation.id}
      item
      xs={12}
      sm={12}
      md={10}
      lg={10}
      className={classes.gridContainer}
    >
      <div ref={refObject}>
        <Paper elevation={0} className={classes.cardPaper}>
          <Avatar
            className={classes.avatar}
            src={`/${reservation.reservationStatus.status.toLowerCase()}.png`}
          />
          <div className={classes.cardContent}>
            <div className={classes.contentRight}>
              <Chip
                className={classes.chipStatus}
                label={reservation?.seat?.description}
              />
              {showReservationName && (
                <Tooltip
                  title={`${reservation?.user?.firstname} ${reservation?.user?.lastname}`}
                  placement="bottom"
                  arrow
                  TransitionComponent={Zoom}
                >
                  <Avatar
                    className={classes.userReservation}
                  >{`${reservation?.user?.firstname.charAt(
                    0
                  )}${reservation?.user?.lastname.charAt(0)}`}</Avatar>
                </Tooltip>
              )}
              <>
                <IconButton
                  className={classes.moreBtn}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl}
                  TransitionComponent={Fade}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      minHeight: ITEM_HEIGHT * 1.5,
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '15ch'
                    }
                  }}
                >
                  {actions?.map(action => {
                    return (
                      (action.isVisible?.(
                        reservation.reservationStatus.status
                      ) ||
                        !action.isVisible) && (
                        <MenuItem
                          className={classes.actionMenuItem}
                          key={action.name}
                          onClick={() => {
                            action.action(reservation.id, dispatch)
                            handleClose()
                          }}
                        >
                          {action.name}
                        </MenuItem>
                      )
                    )
                  })}
                </Menu>
              </>
            </div>
            <Typography variant="h6" className={classes.reservationInfo}>
              {`${reservation?.seat?.room?.description}: ${reservation?.seat?.description}`}
            </Typography>
          </div>
        </Paper>
      </div>
    </Grid>
  )
}

export default Item
