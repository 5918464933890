import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router'
import BannerInfoTable from './BannerInfoTable'
import TableDetail from './TableDetails'
import { useSelector, useDispatch } from 'react-redux'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import { selectFormModalState } from '../../../store/reducers/ConfirmSeatModalReducer'
import { ENVIRONMENT_VARIABLES } from '../../../constants/variables'
import NoData from '../NoData'
import { getGroupReservationsbyDay } from '../../../store/thunk/getReservationsDay'
import {
  filterReservations,
  groupReservationsByDay,
  normalizeReservation
} from '../../services/reservationService'
import { IBigDataContent } from '../../../models/Reservation.model'
import { getdayMonth } from '../../../utils/getdayMonth'
import { countSeats } from 'store/thunk/countSeatsOnDates'
import { countTotalSeats } from '../../../store/thunk/countTotalSeat'
import { getSeatDay } from '../../../utils/getSeatsReservation'

const DayReservationDetail = () => {
  const { pageSize } = ENVIRONMENT_VARIABLES
  const dispatch = useDispatch()
  let { day } = useParams()
  const [wasLoading, setWasLoading] = useState(false)
  const { open } = useSelector(selectFormModalState)

  const [dayMonth, setDayMonth] = useState('')
  const [seats, setSeats] = useState({
    booked: 0,
    available: 0,
    total: 0
  })
  const [bigData, setBigData] = useState<IBigDataContent[]>([])
  const { numberOfSeats, totalSeat } = useSelector(selectReservations)
  const { groupreservations, loading } = useSelector(selectReservations)
  const { pageable = { pageNumber: 0 }, totalPages = 0 } = {
    ...groupreservations
  }
  const [paginationNumber, setPaginationNumber] = useState(pageable.pageNumber)

  useEffect(() => {
    const startDay = moment(day).startOf('day').add(9, 'h').toISOString()
    const endDay = moment(day).endOf('day').add(18, 'h').toISOString()
    setDayMonth(getdayMonth(day))
    dispatch(
      getGroupReservationsbyDay({
        fromDate: moment(day).startOf('day').toISOString(),
        toDate: moment(day).endOf('day').toISOString(),
        page: paginationNumber,
        size: pageSize,
        sort: ['dateStart', 'id'],
        status: ['ACCEPTED', 'REJECTED', 'PENDING']
      })
    )
    dispatch(
      countSeats({
        fromDate: startDay,
        toDate: endDay
      })
    )
    dispatch(countTotalSeats())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationNumber])

  useEffect(() => {
    const normalizedReservations = normalizeReservation(groupreservations)
    const filteredReservations = filterReservations(
      'CANCELLED',
      normalizedReservations
    )
    const groupedByDay = groupReservationsByDay(filteredReservations)

    setBigData(groupedByDay)
  }, [groupreservations])

  useEffect(() => {
    if (numberOfSeats && totalSeat) {
      const seatAvailable = Number(Object.values(numberOfSeats)[0]) || 0

      setSeats({ ...getSeatDay(seatAvailable, totalSeat) })
    }
  }, [numberOfSeats, totalSeat])

  useEffect(() => {
    const startDay = moment(day).startOf('day').add(9, 'h').toISOString()
    const endDay = moment(day).endOf('day').add(18, 'h').toISOString()
    const getSeats = () => {
      dispatch(
        countSeats({
          fromDate: startDay,
          toDate: endDay
        })
      )
      dispatch(countTotalSeats())
    }
    if (wasLoading && !loading && !open) {
      getSeats()
      setWasLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, loading, wasLoading])

  const handlePagination = (val: number) => {
    setPaginationNumber(val - 1)
  }

  const handleStatusReserv = (): void => {
    setWasLoading(true)
  }

  return (
    <>
      {bigData?.length > 0 ? (
        <>
          <BannerInfoTable dayMonth={dayMonth} seats={seats} />
          <TableDetail
            bigData={bigData[0]}
            totalPages={totalPages}
            handlePagination={handlePagination}
            handleStatusReserv={handleStatusReserv}
            paginationNumber={paginationNumber}
          />
        </>
      ) : (
        <NoData
          title="Tutti in SmartWorking!"
          description="nessuna prenotazione trovata"
        />
      )}
    </>
  )
}
export default DayReservationDetail
