import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import axiosInstance from '../../app/axios'
import { showToast } from '../reducers/ToastReducer'
import {
  IApproveReservationContent,
  IReservationContent,
  IReservationsError
} from '../../models/Reservation.model'
import { RES_CONTROLLER } from './../../constants/URLS'
import { AppDispatch } from '../configureStore'
import { isAxiosError } from 'utils/errors'

export const approveReservation = createAsyncThunk<
  IReservationContent,
  IApproveReservationContent,
  {
    rejectValue: AxiosResponse<IReservationsError>
    dispatch: AppDispatch
  }
>(
  'reservation/approveReservation',
  async ({ id, idSeat }, { rejectWithValue, dispatch }) => {
    try {
      const urlParams = `${id}${idSeat ? `/${idSeat}` : ``}`
      const { data } = await axiosInstance.put(
        `${RES_CONTROLLER.approveReservation}/${urlParams}`
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Prenotazione aggiornata con successo!'
        })
      )
      
      const { data: reservation } = await axiosInstance.get(
        `${RES_CONTROLLER.findReservation}?reservationId=${id}`
      )
      return reservation
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
