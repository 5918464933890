import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#37a5d7',
      dark: '#376fd7',
      light: '#37b2d7'
    },
    secondary: {
      main: '#41b6ae',
      dark: '#f9815b',
      light: '#BDE7E5'
    }
  }
})
const responsiveTheme = responsiveFontSizes(theme)

export { responsiveTheme as theme }
