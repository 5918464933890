import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from '../../utils/errors'
import axiosInstance from '../../app/axios'
import { AUTHORITY_CONTROLLER } from '../../constants/URLS'
import { Authority } from '../../models/Auth.model'

export const getAuthorities = createAsyncThunk<
  Authority[],
  undefined,
  {
    rejectValue: AxiosResponse
  }
>('authorities', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get<Authority[]>(
      AUTHORITY_CONTROLLER.getAuthorities
    )
    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError
      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})
