import { getAllUsers } from '../../../store/thunk/userController'
import { getAuthorities } from '../../../store/thunk/authorithyController'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, FormControl, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import { selectUsers } from '../../../store/reducers/userControllerReducer'
import { selectAuthorities } from '../../../store/reducers/AuthorityReducer'
import NoData from '../NoData'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { Add } from '@material-ui/icons'
import { selectGroups } from '../../../store/reducers/groupControllerReducer'
import GroupsTable from './table/GroupsTable'
import { getAllGroups } from 'store/thunk/groupController'
import CreationDialog from './CreationDialog'

const Groups = () => {
  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const authorities = useSelector(selectAuthorities)
  const groups = useSelector(selectGroups)
  const [groupsToShow, setGroupsToShow] = useState(groups)

  const [showClearIcon, setShowClearIcon] = useState('none')

  const [search, setSearch] = useState('')

  const [openCreate, setOpenCreate] = useState(false)

  const [groupCreation, setGroupCreation] = useState({
    id: '',
    name: '',
    description: '',
    organizer: {},
    members: []
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowClearIcon(event.target.value === '' ? 'none' : 'flex')
    setSearch(event.target.value)
    filter(event.target.value)
  }

  const filter = (value: string) => {
    //filter user by name
    var arrayForFilter = [...groups]
    arrayForFilter = arrayForFilter.filter(
      group =>
        group.name.toLowerCase().includes(value.toLowerCase()) ||
        group.organizer.firstname.toLowerCase().includes(value.toLowerCase()) ||
        group.organizer.lastname.toLowerCase().includes(value.toLowerCase())
    )
    setGroupsToShow(arrayForFilter)
  }

  const handleClick = (): void => {
    // TODO: Clear the search input
    setSearch('')
    filter('')
  }

  useEffect(() => {
    dispatch(getAuthorities())
    dispatch(getAllUsers())
    dispatch(getAllGroups())
  }, [])

  useEffect(() => {
    setGroupsToShow(groups)
  }, [groups])

  return (
    <>
      <FormControl>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Cerca gruppo"
          value={search}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon style={{ cursor: 'pointer' }} />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      {groups?.length > 0 ? (
        <>
          <GroupsTable
            groups={groupsToShow}
            users={users}
            authorities={authorities}
            rowsPerPage={6}
          />
        </>
      ) : (
        <NoData
          title="Non c'è nessuno!"
          description="nessun gruppo registrato"
        />
      )}

      {
        <CreationDialog
          editMode={false}
          group={groupCreation}
          setGroup={setGroupCreation}
          users={users}
          open={openCreate}
          setOpen={setOpenCreate}
        />
      }

      <Fab
        style={{ position: 'fixed', bottom: '30px', right: '30px' }}
        color="primary"
        aria-label="add"
        onClick={() => setOpenCreate(true)}
      >
        <Add />
      </Fab>
    </>
  )
}

export default Groups
