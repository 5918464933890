import {
  AppBar,
  CssBaseline,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Header from './Header'
import Sidebar from './Sidebar'

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    padding: '16px 0'
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: 'white',
    padding: '16px 0'
  },
  content: {
    backgroundColor: '#e3f2fd',
    width: '100%',

    margin: 0,
    minHeight: 'calc(100vh - 88px)',
    flexGrow: 1,
    padding: '20px',
    marginTop: '88px',
    marginRight: '20px',
    borderRadius: '12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    [theme.breakpoints.up('md')]: {
      marginLeft: -(260 - 20),
      width: `calc(100% - ${260}px)`
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${260}px)`,
      padding: '0',
      paddingTop: '16px',
      marginRight: '10px'
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  }
}))

const MainLayout = () => {
  const [leftDrawerOpened, setLeftDrawerOpened] = useState<boolean>(false)
  const handleLeftDrawerToggle = () =>
    setLeftDrawerOpened(prevState => !prevState)

  useEffect(() => {
    const r = {
      background: `#FFF`
    }
    const root = document.getElementById('root')
    Object.assign(root?.style, r)
  }, [])

  const classes = useStyles()
  const theme = useTheme() as Theme
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setLeftDrawerOpened(!matchDownMd)
  }, [matchDownMd])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        window={window}
      />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened
          }
        ])}
      >
        <Outlet />
      </main>
    </div>
  )
}

export default MainLayout
