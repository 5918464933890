import { value } from 'constants/conf'
import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
  url: value('REACT_APP_KEYCLOAK_URL'),
  realm: value('REACT_APP_KEYCLOAK_REALM'),
  clientId: value('REACT_APP_KEYCLOAK_CLIENTID')
})

export default keycloak
