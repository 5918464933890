/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations'
import { _t } from 'utils/messages'

export const messages = {
  title: () => _t(translations.NotFoundPage.title, '404 Pagina non trovata'),
  description: () =>
    _t(translations.NotFoundPage.description, 'Pagina non trovata'),
  content: () => _t(translations.NotFoundPage.content, 'Pagina non trovata.')
}
